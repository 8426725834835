import React, { memo } from "react";
import NetworkErrorFactory from "../../api/package/NetworkErrorFactory";
import NetworkErrorScreen from "../NetworkErrorScreen";
import withPageLayout from "../hoc/withPageLayout";
import { compose } from "../../util/functions";

function NotFound(props) {
	const error = NetworkErrorFactory.create(404);
  return (
		<NetworkErrorScreen
			code={error.code}
			message={error.message}
			actionButtonText={error.recoverText}
			onActionButtonClick={error.recoverCallback}
		/>
  );
}

export default memo(
	compose(
		withPageLayout
	)(NotFound)
);
