import React from "react";
import { Avatar as AvatarCore } from "@material-ui/core";

/**
 * Facade component abstracting 3rd party Avatar component
 * exposing only used properties to the application developer.
 * @param {{
 *  alt: string;
 *  src?: string;
 *  variant?: "circular" | "rounded" | "square";
 *  className?: string;
 *  style?: React.CSSProperties;
 * }} props
 * @returns
 */
function Avatar(props) {
	return (
		<AvatarCore
			data-testid={props.testId}
			alt={props.alt}
			component="div"
			src={props.src}
			variant={props.variant}
			className={props.className}
			style={props.style}
		/>
	)
}

Avatar.defaultProps = {
	alt: "John Doe",
	variant: "circular"
}
Avatar.displayName = "Avatar";
export default Avatar;
