import { LICENCE_EXPIRED } from "constants/BackendErrors";
import React, { useCallback } from "react";
import { processExpiredLicence } from "util/licence";
import WorkLogDAO from "../../../api/dao/WorklogDAO";
import UserOnlineStatus from "../../../constants/UserOnlineStatus";
import useApplication from "../../../hooks/useApplication";
import usePageLoading from "../../../hooks/usePageLoading";
import { useSession } from "../../../hooks/useSession";
import SerbianLocalization from "../../../lang/serbian";
import { validateResponse } from "../../../util/api";
import { isBlockingStatusCode, isResponse } from "../../../util/assertions";
import { getCurrentGeolocation } from "../../../util/native";
import { ButtonSwitch } from "../../molecule/ButtonSwitch";

function WorklogButton(props) {
	const {
		inactiveLabel,
		activeLabel,
		testId,
		className,
		size,
		variant,
		color
	} = props;
	const session = useSession();
	const app = useApplication();
	const pageLoading = usePageLoading();

	const checkIn = useCallback(function checkIn() {
		pageLoading.start();
		getCurrentGeolocation()
			.then(({ coords }) => {
				return WorkLogDAO.query()
					.checkIn({ latitude: coords.latitude, longitude: coords.longitude })
					.then(validateResponse)
					.then(() => app.showSuccessToast(SerbianLocalization.check_in.success))
					.then(() => session.user.isOnline = UserOnlineStatus.ONLINE)
					.catch(async error => {
						console.error(error);
						const { response } = error;
						if (isResponse(response)) {
							const { status } = response;
							const body = await response.json();
							if (status === 403 & body.error === LICENCE_EXPIRED) {
								return processExpiredLicence();
							}
							if (isBlockingStatusCode(status)) {
								return app.showNetworkErrorScreen(status);
							}
						}
						return app.showErrorToast(SerbianLocalization.check_in.error);
					})
			})
			.catch(error => {
				app.showWarningToast("Prijava nije moguća bez geolokacije.");
			})
			.finally(pageLoading.stop);;
	}, [pageLoading, app, session.user]);

	const checkOut = useCallback(async function checkOut() {
		pageLoading.start();
		getCurrentGeolocation()
			.then(({ coords }) => {
				return WorkLogDAO.query()
					.checkOut({ latitude: coords.latitude, longitude: coords.longitude })
					.then(validateResponse)
					.then(() => app.showSuccessToast(SerbianLocalization.check_out.success))
					.then(() => session.user.isOnline = UserOnlineStatus.OFFLINE)
					.catch(async error => {
						console.error(error);
						const { response } = error;
						if (isResponse(response)) {
							const { status } = response;
							const body = await response.json();
							if (status === 403 & body.error === LICENCE_EXPIRED) {
								return processExpiredLicence();
							}
							if (isBlockingStatusCode(status)) {
								return app.showNetworkErrorScreen(status);
							}
						}
						app.showErrorToast(SerbianLocalization.check_out.error);
					})
			})
			.catch(error => {
				app.showWarningToast("Prijava nije moguća bez geolokacije.");
			}).finally(pageLoading.stop);
	}, [pageLoading, app, session.user]);

	const changeCheckInState = useCallback(async function changeCheckInState({ nextState }) {
		if (nextState === "active") {
			await checkIn();
		}
		if (nextState === "inactive") {
			await checkOut();
		}
	}, [checkIn, checkOut]);

	return (
		<ButtonSwitch
			className={className}
			testId={testId}
			inactiveLabel={inactiveLabel}
			activeLabel={activeLabel}
			state={session.user.isOnline === UserOnlineStatus.ONLINE ? "active" : "inactive"}
			onChange={changeCheckInState}
			size={size}
			variant={variant}
			color={color}
		/>
	);
}

WorklogButton.defaultProps = {
	inactiveLabel: "Započni radni dan",
	activeLabel: "Završi radni dan"
}
WorklogButton.displayName = "WorklogButton";
export default WorklogButton;
