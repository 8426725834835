export function getCurrentGeolocation() {
	return new Promise((resolve, reject) => {
		function success(position) {
			resolve(position);
		}

		function denial(error) {
			console.error(error);
			reject();
		}

		navigator.geolocation.getCurrentPosition(success, denial);
	});
}

/**
 *
 * @param {(files: File[]) => void} callback
 * @param {{
 *   multiple?: boolean;
 *   accept?: string;
 *   id?: string;
 * }} options
 * @returns
 */
export function chooseFile(callback, options = {}) {
	if (typeof callback !== "function") {
		throw new TypeError("Provided callback is not a function.");
	}
	const input = document.createElement("input");
	input.setAttribute("type", "file");
	["id", "multiple", "accept"].forEach(attribute => {
		if (options[attribute]) {
			input.setAttribute(attribute, options[attribute]);
		}
	});
	input.style.position = "absolute";
	input.style.left = "-999px";
	input.addEventListener("change", onChange, { once: true });
	document.body.appendChild(input);
	input.click();

	function onChange(event) {
		const files = Array.from(event.target.files);
		document.body.removeChild(input);
		if (files.length) {
			callback(files);
		}
	}
}
