import logout from "../network/logout";
import NetworkErrorBuilder from "./NetworkErrorBuilder";

class NetworkErrorFactory {
	static create(statusCode, options = {}) {
		const error = (new NetworkErrorFactory()).create(statusCode);
		for (const [key, value] of Object.entries(options)) {
			error[key] = value;
		}
		return error;
	}

	constructor() {
		if (NetworkErrorFactory.instance) {
			return NetworkErrorFactory.instance;
		}

		this.create = this.create.bind(this);
		NetworkErrorFactory.instance = this;
	}

	refresh() {
		window.location.reload();
	}

	redirectToHome() {
		window.location.href = "/";
	}

	create(statusCode) {
		const builder = NetworkErrorBuilder.builder().setCode(statusCode);
		switch (statusCode) {
			case 401: builder.setTitle("Onemogućen pristup")
				.setMessage("Vaša sesija trenutno nije aktivna. Molimo vas da se ponovo prijavite.")
				.setRecoverText("Nazad na prijavu")
				.setRecoverCallback(logout);
				break;
			case 403: builder.setTitle("Onemogućen pristup")
				.setMessage("Ne posedujete dozvolu za pristup ovoj stranici. Molimo vas da kontaktirate svog administratora.")
				.setRecoverText("Nazad na početnu")
				.setRecoverCallback(this.redirectToHome);
				break;
			case 404: builder.setTitle("Not Found")
				.setMessage("Stranica kojoj pokušavate da pristupite ne postoji.")
				.setRecoverText("Nazad na početnu")
				.setRecoverCallback(this.redirectToHome);
				break;
			case 500: builder.setTitle("Serverska greška")
				.setMessage(
					"Došlo je do privremene greške na serveru i trenutno nije moguće obraditi ovaj zahtev. Molimo vas pokušajte ponovo."
				)
				.setRecoverText("Osveži stranicu")
				.setRecoverCallback(this.refresh);
				break;
			case 502: builder.setTitle("Serverska greška")
				.setMessage("Server trenutno nije u aktivan i ne može da obradi ovaj zahtev. Molimo vas da pokušate kasnije.");
				break;
			case 503: builder.setTitle("Serverska greška")
				.setMessage("Pristup serveru je trenutno onemogućen zbog održavanja. Molimo vas da pokušate kasnije.")
				break;
			default: break;
		}
		return builder.build();
	}
}

export default NetworkErrorFactory;
