import React from "react";
import { CssBaseline } from "@material-ui/core";
import { useClassNames } from "../../assets/styles/layout";

function DefaultLayout(props) {
	const classes = useClassNames();

	return (
		<div className={classes.root}>
			<CssBaseline />
			{props.children}
		</div>
	);
}

DefaultLayout.displayName = "DefaultLayout";
export default DefaultLayout;
