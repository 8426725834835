import React from "react";
import { Typography as TypographyCore } from "@material-ui/core";

/**
 * Facade component abstracting 3rd party Typography component
 * exposing only used properties to the application developer.
 * @param {{
 *  className?: string;
 *  style?: React.CSSProperties;
 *  component?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p" | "span" | "small"
 *  children?: React.ReactChild;
 *  truncated?: boolean;
 * }} props
 * @returns
 */
function Typography(props) {
	return (
		<TypographyCore
			className={props.className}
			style={props.style}
			component={props.component}
			noWrap={props.truncated}
			variant={props.variant}
			title={props.title}
		>
			{props.children}
		</TypographyCore>
	)
}

Typography.defaultProps = {
	component: "p"
}
Typography.displayName = "Typography";
export default Typography;
