import { useCallback, useEffect, useRef, useState } from "react";

/**
 *
 * @param {*} initialState
 * @returns {[any, setState: (currentState?: any) => any, currentStateReference: React.Ref<any>]}
 */
function useHistoryState(initialState) {
	const currentStateReference = useRef(initialState);
	const [state, setInternalState] = useState(initialState);

	useEffect(function onInitialStateChange() {
		currentStateReference.current = state;
	}, [state]);

	useEffect(function beforeComponentRender() {
		return function beforeComponentUnmount() {
			currentStateReference.current = null;
		}
	}, []);

	const setState = useCallback(function setState(nextState) {
		if (typeof nextState === "function") {
			return setInternalState(nextState(currentStateReference.current));
		}
		setInternalState(nextState);
	}, [setInternalState]);

	return [state, setState, currentStateReference];
}

export default useHistoryState;
