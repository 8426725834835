import { useMemo } from "react";

function NetworkErrorIllustration(props) {
	const codeChunks = useMemo(() => props.code.toString().split(""), [props.code]);

	return (
		<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 426.4 357.51" width={300} height={251}>
			<defs>
				<style>
					{`.cls-1 {
							fill: #b3b3b3;
            }
            .cls-2 {
							fill: #e6e6e6;
            }
            .cls-3 {
							fill: #fd6051;
            }
            .cls-4 {
							fill: #fb374c;
            }
            .cls-5,
            .cls-6 {
							fill: #fff;
            }
            .cls-6 {
							font-size: 33.23px;
              font-family: Montserrat-Bold, Montserrat;
              font-weight: 700;
            }
            .cls-7 {
							letter-spacing: -0.01em;
            }
            .cls-8 {
							letter-spacing: 0em;
            }`}
        </style>
			</defs>
			<ellipse className="cls-1" cx="222.77" cy="355.75" rx="168.82" ry="1.76" />
			<polygon className="cls-2" points="285.53 348.63 312.94 328.9 313.52 305.89 309.65 294.92 266.35 289.98 235.11 311.91 244.28 340.9 258.67 346.44 285.53 348.63" />
			<polygon className="cls-1" points="308.55 355.76 322.8 332.19 313.52 305.89 312.94 328.9 285.53 348.63 258.67 346.44 244.28 340.9 248.81 355.21 308.55 355.76" />
			<polygon className="cls-2" points="75.72 354.33 79.6 351.54 79.69 348.27 79.14 346.72 73 346.02 68.58 349.13 69.88 353.24 71.92 354.02 75.72 354.33" />
			<polygon className="cls-1" points="78.98 355.34 81 352 79.69 348.27 79.6 351.54 75.72 354.33 71.92 354.02 69.88 353.24 70.52 355.26 78.98 355.34" />
			<polygon className="cls-2" points="178.1 348.63 191.99 343.9 199.48 335.48 182.49 310.26 143.62 309.32 121.1 330.55 130.33 342.42 141.93 348.08 178.1 348.63" />
			<polygon className="cls-1" points="181.94 355.21 191.99 343.9 178.1 348.63 141.93 348.08 130.33 342.42 140.28 355.21 181.94 355.21" />
			<polygon className="cls-2" points="359.55 350.48 369.32 347.82 371.61 341.71 363.94 326.92 342.01 329.65 328.31 342.81 331.22 346.53 342.01 349.39 359.55 350.48" />
			<polygon className="cls-1" points="366.68 354.87 369.32 347.82 359.55 350.48 342.01 349.39 331.22 346.53 338.17 355.42 366.68 354.87" />
			<polygon className="cls-2" points="99.08 352.08 92.88 350.39 91.42 346.51 96.84 338.77 110.21 338.86 118.9 347.21 117.06 349.57 110.21 351.38 99.08 352.08" />
			<polygon className="cls-1" points="94.56 354.86 92.88 350.39 99.08 352.08 110.21 351.38 117.06 349.57 112.64 355.21 94.56 354.86" />
			<polygon className="cls-2" points="219.16 205.27 218.34 355.76 227.38 355.76 226.87 205.75 219.16 205.27" />
			<polygon className="cls-1" points="226.87 205.75 220.51 205.35 225.37 208.12 224 352.9 220.44 353.96 218.36 351.72 218.34 355.76 227.38 355.76 226.87 205.75" />
			<polygon className="cls-2" points="167.28 132.25 168.1 175.55 199.34 206.24 252.24 205.7 278.27 173.35 277.72 131.97 244.56 99.36 198.52 99.36 167.28 132.25" />
			<polygon className="cls-3" points="174.26 134.84 174.98 172.68 202.29 199.51 248.53 199.04 271.28 170.77 270.81 134.59 241.82 106.09 201.57 106.09 174.26 134.84" />
			<polygon className="cls-1" points="199.34 206.24 252.24 205.7 278.27 173.35 277.72 131.97 270.39 124.76 275.85 133.05 275.26 173.06 250.59 201.84 199.34 203.76 170.33 175.77 167.82 161.01 168.1 175.55 199.34 206.24" />
			<polygon className="cls-4" points="266.19 130.05 270.81 134.59 271.28 170.77 248.53 199.04 202.29 199.51 174.98 172.68 174.81 163.47 176.6 171.97 202.91 196.63 245.93 193.89 267.86 170.32 269.23 135.52 266.19 130.05" />
			<polygon className="cls-5" points="91.91 87.15 111.48 73.06 111.9 56.63 109.13 48.79 78.21 45.27 55.89 60.93 62.45 81.64 72.73 85.59 91.91 87.15" />
			<polygon className="cls-5" points="108.35 92.24 118.53 75.41 111.9 56.63 111.48 73.06 91.91 87.15 72.73 85.59 62.45 81.64 65.68 91.85 108.35 92.24" />
			<polygon className="cls-5" points="64.12 87.45 74.03 84.08 79.38 78.06 67.25 60.05 39.49 59.38 23.41 74.53 30 83.01 38.28 87.06 64.12 87.45" />
			<polygon className="cls-5" points="66.86 92.15 74.03 84.08 64.12 87.45 38.28 87.06 30 83.01 37.11 92.15 66.86 92.15" />
			<polygon className="cls-5" points="125.19 89.07 132.17 87.17 133.81 82.81 128.33 72.24 112.67 74.19 102.88 83.59 104.96 86.25 112.67 88.29 125.19 89.07" />
			<polygon className="cls-5" points="130.28 92.2 132.17 87.17 125.19 89.07 112.67 88.29 104.96 86.25 109.93 92.59 130.28 92.2" />
			<path className="cls-5" d="M184.57,161.86c0,1-35.19,1.8-78.6,1.8s-78.61-.81-78.61-1.8,35.19-1.8,78.61-1.8S184.57,160.87,184.57,161.86Z" transform="translate(-27.36 -70.49)" />
			<polygon className="cls-5" points="227.07 41.32 218.99 39.12 217.09 34.07 224.15 23.97 241.57 24.1 252.9 34.98 250.49 38.05 241.57 40.42 227.07 41.32" />
			<polygon className="cls-5" points="221.17 44.95 218.99 39.12 227.07 41.32 241.57 40.42 250.49 38.05 244.74 45.4 221.17 44.95" />
			<polygon className="cls-5" points="274.21 41.88 293.78 27.79 294.2 11.36 291.43 3.52 260.5 0 238.19 15.66 244.74 36.37 255.02 40.32 274.21 41.88" />
			<polygon className="cls-5" points="290.65 46.98 300.83 30.14 294.2 11.36 293.78 27.79 274.21 41.88 255.02 40.32 244.74 36.37 247.98 46.58 290.65 46.98" />
			<polygon className="cls-5" points="307.49 43.8 314.47 41.9 316.11 37.54 310.63 26.97 294.97 28.93 285.18 38.32 287.26 40.98 294.97 43.02 307.49 43.8" />
			<polygon className="cls-5" points="312.58 46.93 314.47 41.9 307.49 43.8 294.97 43.02 287.26 40.98 292.23 47.32 312.58 46.93" />
			<path className="cls-5" d="M372.57,116.59c0,1-35.2,1.8-78.61,1.8s-78.61-.81-78.61-1.8,35.2-1.8,78.61-1.8S372.57,115.6,372.57,116.59Z" transform="translate(-27.36 -70.49)" />
			<polygon className="cls-5" points="402.91 99.57 407.77 98.24 408.9 95.21 404.66 89.14 394.19 89.21 387.38 95.75 388.82 97.6 394.19 99.02 402.91 99.57" />
			<polygon className="cls-5" points="406.45 101.75 407.77 98.24 402.91 99.57 394.19 99.02 388.82 97.6 392.28 102.02 406.45 101.75" />
			<polygon className="cls-5" points="374.57 99.91 362.8 91.44 362.55 81.55 364.21 76.84 382.81 74.72 396.22 84.14 392.28 96.59 386.1 98.97 374.57 99.91" />
			<polygon className="cls-5" points="364.69 102.97 358.56 92.85 362.55 81.55 362.8 91.44 374.57 99.91 386.1 98.97 392.28 96.59 390.34 102.73 364.69 102.97" />
			<polygon className="cls-5" points="354.55 101.06 350.36 99.92 349.38 97.29 352.67 90.94 362.09 92.12 367.97 97.76 366.72 99.36 362.09 100.59 354.55 101.06" />
			<polygon className="cls-5" points="351.5 102.94 350.36 99.92 354.55 101.06 362.09 100.59 366.72 99.36 363.73 103.18 351.5 102.94" />
			<path className="cls-5" d="M359.24,172.93c0,.6,21.16,1.08,47.26,1.08s47.26-.48,47.26-1.08-21.16-1.08-47.26-1.08S359.24,172.33,359.24,172.93Z" transform="translate(-27.36 -70.49)" />
			<text className="cls-6" transform="translate(188.85 164.42)">
				{codeChunks[0]}
				<tspan className="cls-7" x="22.89" y="0">{codeChunks[1]}</tspan>
				<tspan className="cls-8" x="45.12" y="0">{codeChunks[2]}</tspan>
			</text>
		</svg>
	)
}

NetworkErrorIllustration.displayName = "NetworkErrorIllustration";
NetworkErrorIllustration.defaultProps = {
	code: 422
}

export default NetworkErrorIllustration;