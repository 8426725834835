import React, { Fragment, Suspense, useEffect, useState } from "react";
import { Route, Router, Switch } from "react-router-dom";
// Components
import Login from "./components/pages/Login";
import NotFound from "./components/pages/NotFound";
import PasswordResetPage from "./components/pages/PasswordResetPage";
// Models, libraries, hooks...
import AppRoute from "./constants/AppRoute";
import { AuthenticatedRoute } from "./components/molecule/AuthenticatedRoute";
import { PageLoading } from "./components/molecule/PageLoading";
import DialogContainer from "./containers/DialogContainer";
import PageLoadingContainer from "./containers/LoadingContainer";
import ToastContainer from "./containers/ToastContainer";
import SessionService from "./services/SessionService";
import EventService from "./services/EventService";
import { noop } from "./util/functions";
import JobReportService from "./services/JobReportService";
import GoogleMapService from "./services/GoogleMapService";
import PrivacyPolicyPage from "./components/pages/PrivacyPolicyPage";
import TermsAndConditionsPage from "./components/pages/TermsAndConditionsPage";
import ApplicationService from "./services/ApplicationService";
import BrowserHistory from "BrowserHistory";

function App() {
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		Promise.allSettled([
			SessionService.boot(),
			EventService.boot(),
			JobReportService.boot(),
			GoogleMapService.boot(),
			ApplicationService.boot()
		]).then(responses => {
			const session = responses[0].value;
			if (!session.isExpired()) {
				return session.loadSessionUser();
			}
		})
			.catch(noop)
			.finally(() => {
				setLoading(false);
			});
	}, []);

	if (loading) {
		return <PageLoading isLoading={true} />
	}

	return (
		<Fragment>
			<PageLoadingContainer />
			<DialogContainer />
			<ToastContainer />
			<Suspense fallback={<PageLoading isLoading={true} />}>
				<Router history={BrowserHistory}>
					<Switch>
						{/* Public routes */}
						<Route exact path={AppRoute.LOGIN} component={Login} />
						<Route exact path={AppRoute.PWD_RESET} component={PasswordResetPage} />
						<Route exact path={AppRoute.PRIVACY_POLICY} component={PrivacyPolicyPage} />
						<Route exact path={AppRoute.TERMS_AND_CONDITIONS} component={TermsAndConditionsPage} />
						{/* Protected routes */}
						<AuthenticatedRoute
							exact
							path={[AppRoute.HOME, AppRoute.JOBS]}
							component={React.lazy(() => import('./components/pages/Jobs'))} />
						<AuthenticatedRoute
							exact
							path={[AppRoute.JOB, `${AppRoute.JOB}/:id`]}
							component={React.lazy(() => import('./components/pages/JobAdd'))} />
						<AuthenticatedRoute
							exact
							path={[
								`${AppRoute.JOB}/:jobId${AppRoute.COMMENT}`,
								`${AppRoute.JOB}/:jobId${AppRoute.COMMENT}/:id`
							]}
							component={React.lazy(() => import('./components/pages/JobCommentAdd'))} />
						<AuthenticatedRoute
							exact
							path={AppRoute.LOCATIONS}
							component={React.lazy(() => import('./components/pages/Locations'))}
						/>
						<AuthenticatedRoute
							exact
							path={[
								AppRoute.LOCATION,
								`${AppRoute.LOCATION}/:id`
							]}
							component={React.lazy(() => import('./components/pages/LocationAdd'))} />
						<AuthenticatedRoute
							exact
							path={AppRoute.BUILDING_SITES}
							component={React.lazy(() => import('./components/pages/BuildingSites'))}
						/>
						<AuthenticatedRoute
							exact
							path={[
								AppRoute.BUILDING_SITE,
								`${AppRoute.BUILDING_SITE}/:id`
							]}
							component={React.lazy(() => import('./components/pages/BuildingSiteAdd'))}
						/>
						<AuthenticatedRoute
							exact
							path={AppRoute.CONTRACTORS}
							component={React.lazy(() => import('./components/pages/Contractors'))}
						/>
						<AuthenticatedRoute
							exact
							path={[
								AppRoute.CONTRACTOR,
								`${AppRoute.CONTRACTOR}/:id`
							]}
							component={React.lazy(() => import('./components/pages/ContractorAdd'))} />
						<AuthenticatedRoute
							exact
							path={AppRoute.USERS}
							component={React.lazy(() => import('./components/pages/Users'))} />
						<AuthenticatedRoute
							exact
							path={[
								AppRoute.USER,
								`${AppRoute.USER}/:id`
							]}
							component={React.lazy(() => import('./components/pages/UserAdd'))} />
						<AuthenticatedRoute
							exact
							path={AppRoute.PROFILE + '/:id'}
							component={React.lazy(() => import('./components/pages/Profile'))} />
						<AuthenticatedRoute
							exact
							path={AppRoute.MAPS}
							component={React.lazy(() => import('./components/pages/Maps'))} />
						<AuthenticatedRoute path="*" component={NotFound} />
					</Switch>
				</Router>
			</Suspense>
		</Fragment>
	);
}

App.displayName = "App";
export default App;
