import React, { useCallback } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
// Material.UI
import Container from "@material-ui/core/Container";
// Components
import { LoginForm } from "../organism/LoginForm";
// Models, libraries, hooks...
import { useStyles } from "../../assets/styles/login.js";
import logo from "../../assets/images/logo.png";
import AppRoute from "../../constants/AppRoute";
import useApplication from "../../hooks/useApplication";

function Login(props) {
	const classes = useStyles();
	const { push, location } = useHistory();
	const { state } = location;
	const application = useApplication();

	const submitForm = useCallback(async function submitForm(session) {
		const user = await session.loadSessionUser();

		if (user.error) {
			application.showErrorToast(user.error);
			return;
		}

		push(state?.referrer || AppRoute.HOME);
	}, [push, state, application]);

	return (
		<Container className={classes.loginContainer}>
			<div className={classes.loginCard}>
				<div>
					<div className={classes.loginLogo}>
						<img src={logo} alt='company-logo' />
					</div>
					<LoginForm onSubmit={submitForm} />
					<div className={classes.centeredLink}>
						<Link to={AppRoute.PWD_RESET}>Zaboravio sam lozinku</Link>
					</div>
				</div>
			</div>
		</Container>
	);
}

Login.displayName = "Login";
export default Login;
