import html2pdf from "html2pdf.js";
import { pluck } from "../util/functions";
import { fromDateToString } from "../util/transforms";

export default class JobReportService {
	/**
	 *
	 * @returns {JobReportService}
	 */
	static getInstance() {
		return JobReportService.instance;
	}

	constructor() {
		if (JobReportService.instance instanceof JobReportService) {
			return JobReportService.instance;
		}
		JobReportService.instance = this;
	}

	static boot() {
		return new JobReportService();
	}

	constructHeader() {
		const header = document.createElement("header");
		header.style = `margin-bottom: 0.5cm`;
		header.innerText = `Izveštaj generisan u ${(new Date(Date.now())).toISOString()}`;
		return header;
	}

	constructJobInfo(job) {
		const container = document.createElement("div");
		const fields = [
			["name", "Naziv zadatka"],
			["location.name", "Naziv lokacije"],
			["buildingSite.name", "Naziv objekta"],
			["deadline", "Datum i vreme"],
			["status.name", "Status"],
			["description", "Opis zadatka"]
		];
		container.innerHTML = `
<table>
  <tbody>
		${fields.map(([key, label]) => {
			return (
				`
<tr>
  <th style="text-align: left; min-width: 4cm;">${label}:</th>
	<td>${pluck(key, job, "N/A")}</td>
</tr>
`
			);
		}).join("")}
	</tbody>
</table>
<hr />
`;
		return container;
	}

	constructAttachments(attachments) {
		const container = document.createElement("div");
		const header = document.createElement("h2");
		header.innerText = "Prilozi";
		container.appendChild(header);
		const list = document.createElement("ul");
		for (const attachment of attachments) {
			const item = document.createElement("li");
			item.style = "padding-left: 0.2cm";
			item.innerText = attachment.name;
			list.appendChild(item);
		}
		if (attachments.length === 0) {
			const item = document.createElement("li");
			item.innerText = "Zadatak nema nakačene priloge.";
			list.style = list.style + "list-style-type: none;"
			list.appendChild(item);
		}
		container.appendChild(list);
		return container;
	}

	constructComments(comments) {
		const container = document.createElement("div");

		function generateRow(comment) {
			return (
				`
<tr>
  <td><p style="word-wrap: break-word">${comment.comment}</p></td>
	<td style="min-width: 3cm">${fromDateToString("YYYY-MM-DD HH:mm:ss", new Date(comment.updateDate))}</td>
	<td style="min-width: 3cm">${comment.user.firstName + " " + comment.user.lastName}</td>
</tr>
`
			);
		}

		container.innerHTML = (
			`
<h2 style="margin: 0; margin-bottom: 0.2cm;">Komentari</h2>
<table style="width: 100%">
	<thead>
		<tr style='background-color: #FF3E3E; color: white; height: 1cm; text-align: left'>
			<th>Komentar</th>
			<th>Postavljen</th>
			<th>Autor</th>
		</tr>
	</thead>
	<tbody>
		${comments.map(generateRow).join("")}
	</tbody>
</table>
<page>
    <page_header footer='page'></page_header>
    <page_footer></page_footer>
</page>
`
		);

		return container;
	}

	generate(job, attachments = [], comments = []) {
		const options = {
			margin: [0.5, 0.5, 1.2, 0.5],
			filename: `${job.name}.pdf`,
			image: { type: 'jpeg', quality: 1 },
			html2canvas: { scale: 2, scrollX: 0, scrollY: 0, letterRendering: true },
			jsPDF: { unit: 'cm', format: 'a4', orientation: 'portrait' },
			pagebreak: { mode: [ 'css', 'legacy'] }
		};
		const container = document.createElement("div");
		container.style = `box-sizing: border-box; font-family: "Verdana"; width: 100%;`;
		container.appendChild(this.constructHeader());
		container.appendChild(this.constructJobInfo(job));
		container.appendChild(this.constructAttachments(attachments));
		container.appendChild(this.constructComments(comments));
		return html2pdf().from(container).set(options).toPdf().get('pdf').then(function (pdf) {
			const totalPages = pdf.internal.getNumberOfPages();

			console.log(pdf.internal.pageSize.getWidth());
			for (let i = 1; i <= totalPages; i++) {
				pdf.setPage(i);
				pdf.setFontSize(10);
				pdf.setTextColor(150);
				pdf.text('Stranica ' + i + ' od ' + totalPages, pdf.internal.pageSize.getWidth() / 2 - 1, pdf.internal.pageSize.getHeight() - 0.5);
			}
		}).save();
	}
}
