import BrowserHistory from 'BrowserHistory';
import AppRoute from 'constants/AppRoute';
import ApplicationService from "services/ApplicationService";
import SessionService from "services/SessionService";

export function processExpiredLicence() {
	SessionService.getInstance().terminate();
	BrowserHistory.push(AppRoute.LOGIN);
	setTimeout(() => {
		ApplicationService.getInstance()
			.showErrorToast("Licenca koju koristite je istekla, molimo vas da kontaktirajte administratora!");
	}, 100);
}
