/**
 * Base Builder class defining ground methods which every child needs to implement.
 * @method reset Reset every underlying builder property.
 * @method build Generate prepared data in the desired shape.
 */
class Builder {
	constructor() {
		this.reset = this.reset.bind(this);
		this.build = this.build.bind(this);
		this.reset();
	}
	/**
	 * Reset every underlying builder property.
	 */
	reset() {
		return this;
	};
	/**
	 * Generate prepared data in the desired shape.
	 */
	build() { };
}

export default Builder;