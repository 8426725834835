import React from "react";
import { Drawer, Box } from "@material-ui/core";
import { useClassNames } from "./Sidebar.styles";
import { SidebarAvatar } from "../../molecule/SidebarAvatar";
import { Logo } from "../../molecule/Logo";
import { SidebarNavigationMenu } from "../../molecule/SidebarNavigationMenu";

function Sidebar(props) {
	const classes = useClassNames();
	return (
		<Drawer
			className={classes.sidebar}
			variant='permanent'
			classes={{
				paper: classes.sidebar,
			}}
			anchor='left'
		>
			<SidebarAvatar />
			<Logo />
			<Box mt={2}>
				<SidebarNavigationMenu />
			</Box>
		</Drawer>
	);
};

Sidebar.displayName = "Sidebar";
export default Sidebar;
