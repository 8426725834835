import ApiEndpoint from "../../constants/ApiEndpoint";
import { sendAuthenticatedRequest } from "../network/sendAuthenticatedRequest";
import { sendRequest } from "../network/sendRequest";
import { DAO } from "../package/DAO";

class UserDAO extends DAO {
	static query() {
		return new UserDAO();
	}
	constructor() {
		super(ApiEndpoint.USERS);
	}
	changePassword(changePasswordModel) {
		return sendAuthenticatedRequest(
			this.request.setURL(`${this.endpoint}/changePassword`)
				.setBody(changePasswordModel)
				.setMethod("POST")
				.build()
		);
	}
	forgotPassword({ email }) {
		return sendRequest(
			this.request.setURL(`${this.endpoint}/forgotPassword`)
				.where("email", email)
				.setMethod("POST")
				.build()
		)
	}
	activateLicence(userId) {
		return sendAuthenticatedRequest(
			this.request.setURL(`${this.endpoint}/activateLicence/${userId}`)
				.setMethod("POST")
				.build()
		);
	}
	suspendLicence(userId) {
		return sendAuthenticatedRequest(
			this.request.setURL(`${this.endpoint}/suspendLicence/${userId}`)
				.setMethod("POST")
				.build()
		);
	}
	/**
	 *
	 * @param {number} userId
	 * @param {{
	 *  id: number;
	 *  status: string;
	 * }} userStatusModel
	 */
	changeEmployeeStatus(userId, userStatusModel) {
		return sendAuthenticatedRequest(
			this.request.setURL(`${this.endpoint}/employeeStatus/${userId}`)
				.setMethod("PUT")
				.setBody(userStatusModel)
				.build()
		);
	}
}

export default UserDAO;
