import NetworkError from "./NetworkError";

class NetworkErrorBuilder {
	static builder() {
		return new NetworkErrorBuilder();
	}

	constructor() {
		this.setTitle = this.setTitle.bind(this);
		this.setMessage = this.setMessage.bind(this);
		this.setCode = this.setCode.bind(this);
		this.setVisualize = this.setVisualize.bind(this);
		this.setRecoverText = this.setRecoverText.bind(this);
		this.reset = this.reset.bind(this);
		this.build = this.build.bind(this);
		this.reset();
	}

	setTitle(nextTitle) {
		this.title = nextTitle;
		return this;
	}

	setMessage(nextMessage) {
		this.message = nextMessage;
		return this;
	}

	setCode(nextCode) {
		this.code = nextCode;
		return this;
	}

	setVisualize(nextVisualize = false) {
		this.visualize = nextVisualize;
		return this;
	}

	setRecoverText(nextRecoverText = "") {
		this.recoverText = nextRecoverText;
		return this;
	}

	setRecoverCallback(nextRecoverCallback = null) {
		this.recoverCallback = nextRecoverCallback;
		return this;
	}

	reset() {
		this.code = null;
		this.title = "";
		this.message = "";
		this.visualize = false;
		this.recoverText = "";
		this.recoverCallback = null;
		return this;
	}

	build() {
		return new NetworkError(this.code, this.title, this.message, this.visualize, this.recoverText, this.recoverCallback);
	}
}

export default NetworkErrorBuilder;