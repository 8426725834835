import { GOOGLE_MAPS_API_KEY } from "../constants/backend";
import { isDevelopmentEnvironment, isString } from "../util/assertions";
import predictions from "./data/predictions.json";
import place from "./data/place.json";

/* global google */
class GoogleMapService {
	static BASE_URL = "https://maps.googleapis.com/maps/api/js"
	static LIBRARIES = ["places"];

	/**
	 *
	 * @returns {GoogleMapService}
	 */
	static getInstance() {
		return GoogleMapService.instance;
	}

	static boot() {
		return new Promise((resolve) => {
			const service = new GoogleMapService();

			if (window.google && window.google.maps && window.google.maps.version) {
				service.infoWindow = new google.maps.InfoWindow();
				service.autoCompleteService = new google.maps.places.AutocompleteService();
				service.placesService = new google.maps.places.PlacesService(document.createElement('div'));
				return resolve();
			}

			const onLoad = () => {
				service.infoWindow = new google.maps.InfoWindow();
				service.autoCompleteService = new google.maps.places.AutocompleteService();
				service.placesService = new google.maps.places.PlacesService(document.createElement('div'));
				resolve();
			}

			const script = document.createElement('script');
			const URL = GoogleMapService.BASE_URL + '?' + [
				'libraries=' + GoogleMapService.LIBRARIES.join(),
				!isDevelopmentEnvironment() && 'key=' + GOOGLE_MAPS_API_KEY,
			].filter(Boolean).join('&');

			script.src = URL;
			script.id = "google-map-script";
			script.type = "text/javascript";
			script.defer = true;
			script.async = true;
			script.addEventListener('load', onLoad, { once: true });
			document.body.appendChild(script);
		});
	}

	constructor() {
		if (GoogleMapService.instance instanceof GoogleMapService) {
			return GoogleMapService.instance;
		}

		this.markers = new Map();
		this.zoomLevel = 15;
		this.fullscreenControl = false;
		this.getPredictions = this.getPredictions.bind(this);
		this.getPlaceDetails = this.getPlaceDetails.bind(this);
		this.attachMap = this.attachMap.bind(this);
		this.detachMap = this.detachMap.bind(this);
		GoogleMapService.instance = this;
	}

	getPredictions(searchTerm) {
		const request = {
			input: searchTerm,
			types: ["geocode"],
			componentRestrictions: {
				country: ["rs"]
			}
		};
		return new Promise((resolve, reject) => {
			if (isDevelopmentEnvironment()) {
				resolve(predictions);
			}

			this.autoCompleteService.getPlacePredictions(request, (predictions, status) => {
				if (status !== google.maps.places.PlacesServiceStatus.OK) {
					return reject(status);
				}
				return resolve(predictions);
			})
		})
	}

	getPlaceDetails(placeId) {
		return new Promise((resolve, reject) => {
			if (isDevelopmentEnvironment()) {
				return resolve({
					...place,
					geometry: {
						location: {
							lat: () => place.geometry.location.lat,
							lng: () => place.geometry.location.lng,
						}
					}
				});
			}
			const request = {
				placeId,
				// Each field is charged differently, be extra careful to fetch only fields required by the application.
				fields: ['formatted_address', "geometry.location"]
			}
			function getDetailsCallback(result, status) {
				if (status !== google.maps.places.PlacesServiceStatus.OK) {
					return reject(status);
				}
				resolve(result);
			}
			this.placesService.getDetails(request, getDetailsCallback);
		});
	}

	/**
	 *
	 * @param {HTMLDivElement|string} containerElement
	 */
	attachMap(containerElement) {
		if (containerElement) {
			if (isString(containerElement)) {
				containerElement = document.getElementById(containerElement);
			}

			this.map = new google.maps.Map(containerElement, {
				center: { lat: 44.79883028568868, lng: 20.451308146392908 },
				zoom: this.zoomLevel, // Streets by default.
				fullscreenControl: this.fullscreenControl,
				mapTypeId: google.maps.MapTypeId.ROADMAP,
				styles: [
					{
						"featureType": "administrative",
						"elementType": "geometry",
						"stylers": [
							{
								"visibility": "off"
							}
						]
					},
					{
						"featureType": "poi",
						"stylers": [
							{
								"visibility": "off"
							}
						]
					},
					{
						"featureType": "road",
						"elementType": "labels.icon",
						"stylers": [
							{
								"visibility": "off"
							}
						]
					},
					{
						"featureType": "transit",
						"stylers": [
							{
								"visibility": "off"
							}
						]
					}
				]
			});
		}
	}

	detachMap() {
		this.removeMarkers();
		this.map = null;
	}

	/**
	 *
	 * @param {"user" | "location"} type
	 */
	getIcon(type) {
		// const base = "http://maps.google.com/mapfiles/kml/shapes";
		const base = "/images/maps/";
		if (type === "user") {
			return base + "active-user.png";
		}
		if (type === "location") {
			return base + "maps-location.png";
		}
		return base + "pin.png";
	}

	createMarker(markerData) {
		if (!markerData.markerId || this.markers.has(markerData.markerId)) {
			return;
		}

		const marker = new google.maps.Marker({
			position: new google.maps.LatLng(markerData.latitude, markerData.longitude),
			map: this.map,
			title: markerData.title,
			descr: markerData.description,
			icon: {
				url: this.getIcon(markerData.type),
				scaledSize: new google.maps.Size(30, 30)
			}
		});

		const onGoogleMarkerClick = () => {
			// this.map.setZoom(18);
			this.map.setCenter(marker.getPosition());
			this.infoWindow.setContent(markerData.content || marker.getTitle());
			this.infoWindow.setPosition(marker.getPosition());
			this.infoWindow.setOptions({ pixelOffset: new google.maps.Size(0, -30) });
			this.infoWindow.open(this.map);
		}

		const listener = google.maps.event.addListener(marker, "click", onGoogleMarkerClick);
		const customMarker = { marker, listener };
		this.markers.set(markerData.markerId, customMarker);
	}

	removeMarker(markerId) {
		if (!markerId || !this.markers.has(markerId)) {
			return;
		}
		const customMarker = this.markers.get(markerId);
		// Remove marker pointers and prepare it for garbage collector.
		customMarker.listener.remove();
		customMarker.marker.setMap(null);
		this.markers.delete(markerId);
	}

	removeMarkers() {
		Array.from(this.markers.keys()).forEach(key => this.removeMarker(key));
	}

	setCenter(lat, long) {
		this.map.setCenter(new google.maps.LatLng(lat, long));
	}
}

export default GoogleMapService;
