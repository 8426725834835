import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useClassNames } from "./LoginForm.styles";
import { EMAIL_REGEX } from "../../../constants/patterns";
import { TextField } from "../../atom/TextField";
import { Button } from "../../atom/Button";
import { Typography } from "../../atom/Typography";
import { PasswordField } from "../../atom/PasswordField";
import { FormRow } from "../../molecule/FormRow";
import { isFunction } from "../../../util/assertions";
import { useSession } from "../../../hooks/useSession";

function LoginForm(props) {
	const {
		username,
		password,
		onSubmit
	} = props;
	const defaultValues = useMemo(() => {
		return {
			username: username || "",
			password: password || ""
		}
	}, [username, password]);
	const canSave = isFunction(onSubmit);

	const classes = useClassNames();
	const { control, handleSubmit, formState: { errors }, reset } = useForm({
		defaultValues
	});
	useEffect(() => {
		reset(defaultValues)
	}, [defaultValues, reset]);
	const [submitError, setSubmitError] = useState("");
	const session = useSession();

	const handleFormSubmit = useCallback(async function handleFormSubmit(data) {
		const {error, session: authenticatedSession} = await session.authenticate(data.username, data.password);
		if (error) {
			setSubmitError(error);
			return;
		}
		onSubmit(authenticatedSession);
	}, [onSubmit, session]);

	return (
		<form onSubmit={handleSubmit(handleFormSubmit)} className={classes.form}>
			<FormRow className={classes.formRow}>
				<Controller
					control={control}
					name="username"
					rules={{
						required: "Korisničko ime je obavezno.",
						pattern: {
							value: EMAIL_REGEX,
							message: "Uneseni format email adrese trenutno nije podržan."
						}
					}}
					render={({ field }) => {
						return (
							<TextField
								id="username"
								value={field.value}
								onChange={field.onChange}
								label="Korisničko ime"
								readOnly={!canSave}
								fullWidth={true}
								error={Boolean(errors.username)}
								helperText={errors.username?.message}
							/>
						)
					}}
				/>
			</FormRow>
			<FormRow className={classes.formRow}>
				<Controller
					control={control}
					name="password"
					rules={{
						required: "Lozinka je obavezna.",
					}}
					render={({ field }) => {
						return (
							<PasswordField
								id="password"
								value={field.value}
								onChange={field.onChange}
								label="Lozinka"
								readOnly={!canSave}
								fullWidth={true}
								error={Boolean(errors.password)}
								helperText={errors.password?.message}
							/>
						)
					}}
				/>
			</FormRow>
			<FormRow className={classes.formRow}>
				{submitError && <Typography className={classes.errorMessage}>{submitError}</Typography>}
				{canSave && <Button fullWidth={true} type="submit" size="large">Uloguj se</Button>}
			</FormRow>
		</form>
	)
}

LoginForm.displayName = "LoginForm";
export default LoginForm;
