import React from "react";
import { Box, Button } from "@material-ui/core";
import { useStyles } from "../assets/styles/network-error-screen";
import NetworkErrorIllustration from "./NetworkErrorIlustration";
import { isFunction } from "../util/assertions";
import { Typography } from "./atom/Typography";

/**
 *
 * @param {{
 *   code: number;
 *   message: string;
 *   actionButtonText: string;
 *   onActionButtonClick: (event: React.MouseEvent) => void;
 * }} props
 * @returns
 */
function NetworkErrorScreen(props) {
	const classes = useStyles();
	return (
		<Box
			display="flex"
			flexDirection="column"
			alignItems="center"
			justifyContent="center"
			className={classes.container}
		>
			<NetworkErrorIllustration code={props.code} />
			<Typography className={classes.content}>{props.message}</Typography>
			{isFunction(props.onActionButtonClick) && (
				<Button
					color='primary'
					variant='contained'
					className={classes.actionButton}
					onClick={props.onActionButtonClick}
				>{props.actionButtonText}</Button>
			)}
		</Box>
	)
}

NetworkErrorScreen.displayName = "NetworkErrorScreen";
export default NetworkErrorScreen;
