import ApiEndpoint from "../../constants/ApiEndpoint";
import { sendAuthenticatedRequest } from "../network/sendAuthenticatedRequest";
import { DAO } from "../package/DAO";

class UserImageDAO extends DAO {
	static query(id) {
		return new UserImageDAO(id);
	}
	constructor(id) {
		super(`${ApiEndpoint.USERS}/profilePicture/${id}`);
	}
	download(awsKey) {
		return sendAuthenticatedRequest(
			this.request.setURL(this.endpoint)
				.where("awsKey", awsKey)
				.build()
		);
	}
	upload(body) {
		return sendAuthenticatedRequest(
			this.request.setURL(this.endpoint)
				.setMethod("POST")
				.setBody(body)
				.build()
		);
	}
}

export default UserImageDAO;
