import { useEffect, useState } from "react";
import UserImageDAO from "../../api/dao/UserImageDAO";
import { validateResponse } from "../../util/api";
import { asyncPipe } from "../../util/functions";
import { pluckBlob } from "../../util/objects";

export default function withProfilePicture(WrappedComponent) {
	/**
	 *
	 * @param {{
	 * 	profilePictureAwsKey: string;
	 *  userId: string;
	 * }} props
	 * @returns {JSX.Element}
	 */
	function ProfilePictureLoader(props) {
		const [pictureURL, setPictureURL] = useState("");

		useEffect(() => {
			if (props.profilePictureAwsKey) {
				UserImageDAO.query(props.userId)
					.download(props.profilePictureAwsKey)
					.then(asyncPipe(validateResponse, pluckBlob))
					.then(blob => setPictureURL(URL.createObjectURL(blob)))
					.catch(error => console.error(error));
			}
		}, [props.userId, props.profilePictureAwsKey]);

		return (
			<WrappedComponent {...props} src={pictureURL} />
		)
	}

	ProfilePictureLoader.displayName = "ProfilePictureLoader";
	return ProfilePictureLoader;
}
