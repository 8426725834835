import { makeStyles } from "@material-ui/core/styles";

export const useClassNames = makeStyles((theme) => ({
	logoItem: {
		textAlign: "center",
	},
	logoImage: {
		width: "200px",
		height: "70px"
	},
	logoImageSmall: {
		width: "60px",
		height: "21px"
	},
}));
