import ApiEndpoint from "../../constants/ApiEndpoint";
import { sendAuthenticatedRequest } from "../network/sendAuthenticatedRequest";
import { DAO } from "../package/DAO";

class WorkLogDAO extends DAO {
	static query() {
		return new WorkLogDAO();
	}
	constructor() {
		super(ApiEndpoint.WORKLOG);
	}
	checkIn({ latitude, longitude } = {}) {
		const body = new FormData();
		body.set("platform", "WEB")
		body.set("deviceId", "1000");
		body.set("latitude", latitude);
		body.set("longitude", longitude);
		return sendAuthenticatedRequest(
			this.request
				.setMethod("POST")
				.setURL(this.endpoint + "/checkIn")
				.setBody(body)
				.build()
		);
	}
	checkOut({ latitude, longitude } = {}) {
		const body = new FormData();
		body.set("platform", "WEB")
		body.set("deviceId", "1000");
		body.set("latitude", latitude);
		body.set("longitude", longitude);
		return sendAuthenticatedRequest(
			this.request
				.setMethod("POST")
				.setURL(this.endpoint + "/checkOut")
				.setBody(body)
				.build()
		);
	}
	listReportsForUser(userId) {
		return sendAuthenticatedRequest(
			this.request.setURL(`${this.endpoint}/report/${userId}`).build()
		);
	}
}

export default WorkLogDAO;
