import React, { useCallback, useMemo } from "react";
import { Button as ButtonCore } from "@material-ui/core";

/**
 * Facade component abstracting underlying 3rd party Button component
 * exposing only necessary properties to the application developer.
 * @param {{
 *  color?: "primary" | "secondary" | "default";
 *  disabled?: boolean;
 *  className?: string;
 *  variant?: "text" | "outlined" | "contained";
 *  style?: React.CSSProperties;
 *  type?: "button" | "submit" | "reset";
 *  autoFocus?: boolean;
 *  fullWidth?: boolean;
 *  onClick?: (event, props) => void;
 *  rounded?: boolean;
 *  testId?: string;
 * }} props
 */
function Button(props) {
	const handleClick = useCallback(function handleButtonClick(event) {
		props.onClick(event, props);
	}, [props]);

	const styles = useMemo(() => ({
		...props.style,
		borderRadius: props.rounded ? "50%" : undefined,
		aspectRatio: props.rounded ? "1 / 1" : undefined
	}), [props.style, props.rounded])

	return (
		<ButtonCore
			data-testid={props.testId}
			color={props.color}
			variant={props.variant}
			disabled={props.disabled}
			className={props.className}
			style={styles}
			type={props.type}
			autoFocus={props.autoFocus}
			fullWidth={props.fullWidth}
			size={props.size}
			onClick={typeof props.onClick === "function" ? handleClick : undefined}
		>
			{props.children}
		</ButtonCore>
	)
}

Button.defaultProps = {
	variant: "contained",
	color: "primary",
	type: "button"
}
Button.displayName = "Button";
export default Button;
