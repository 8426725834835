class NetworkError extends Error {
	constructor(code, title, message, visualize, recoverText, recoverCallback = null) {
		super(message);
		this.response = new Response(message, {
			status: code,
		});
		this.code = code;
		this.title = title;
		this.message = message;
		this.visualize = visualize;
		this.recoverText = recoverText;
		this.recoverCallback = recoverCallback;
	}
}
export default NetworkError;