import React from "react";

function LinkExternal(props) {
	const {
		testId,
		className,
		to,
		rel,
		children
	} = props;

	return (
		<a data-testid={testId} className={className} href={to} rel={rel}>
			{children}
		</a>
	)
}

LinkExternal.defaultProps = {
	rel: "nofollow"
}
LinkExternal.displayName = "LinkExternal";
export default LinkExternal;
