import { useCallback, useState } from "react";

function useForceRefresh() {
	const [, setState] = useState({});

	const refresh = useCallback(function refresh() {
		setState({});
	}, []);

	return refresh;
}

export default useForceRefresh;