import React from "react";
import { Controller } from "react-hook-form";
import { EMAIL_REGEX } from "../../../constants/patterns";
import { pluck } from "../../../util/functions";
import { TextField } from "../../atom/TextField";

function EmailField({ control, readOnly }) {
	return (
		<Controller
			name="email"
			control={control}
			rules={{
				required: "Email adresa je obavezna.",
				maxLength: {
					value: 150,
					message: "Email adresa ne može imati više od 150 karaktera."
				},
				pattern: {
					value: EMAIL_REGEX,
					message: "Uneseni format email adrese trenutno nije podržan."
				},
			}}
			render={({ field, formState: { errors } }) => {
				return (
					<TextField
						value={field.value}
						onChange={field.onChange}
						readOnly={readOnly}
						id="email"
						label="Email adresa"
						helperText={pluck("email.message", errors)}
						error={Boolean(errors.email)}
						fullWidth={true}
						type="email"
					/>
				)
			}}
		/>
	)
}

EmailField.displayName = "EmailField";
export default EmailField;
