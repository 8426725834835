import React from "react";
import { Box } from "@material-ui/core";

function FormRow(props) {
	return (
		<Box mb={2} className={props.className}>
			{props.children}
		</Box>
	)
}

FormRow.displayName = "FormRow";
export default FormRow;