import { useCallback, useEffect } from "react";
import useHistoryState from "../hooks/useHistoryState";
import { isFunction } from "../util/assertions";
import { createRandomString } from "../util/strings";
import { ConfirmationDialog } from "../components/molecule/ConfirmationDialog";
import { SingleActionDialog } from "../components/molecule/SingleActionDialog";
import { DialogBuilder } from "../package/DialogBuilder";
import useEvent from "../hooks/useEvent";

function DialogContainer(props) {
	const [dialogs, setDialogs] = useHistoryState([]);
	const event = useEvent();

	const onReceivedMessage = useCallback(function onReceivedMessage(transaction) {
		if (Object.values(DialogBuilder.Type).includes(transaction.type)) {
			const id = createRandomString();
			function onClose(event, props) {
				setDialogs(dialogs => dialogs.filter(dialog => dialog.id !== id));
				if (isFunction(transaction.onClose)) {
					transaction.onClose(event, props);
				}
			}
			function onSubmit(event, props) {
				setDialogs(dialogs => dialogs.filter(dialog => dialog.id !== id));
				transaction.onSubmit(event, props);
			}
			const dialogData = {
				id,
				open: true,
				title: transaction.title,
				content: transaction.content,
				closeButtonText: transaction.closeButtonText,
				submitButtonText: transaction.submitButtonText,
				type: transaction.type,
				onClose,
				onSubmit: isFunction(transaction.onSubmit) ? onSubmit : null
			};
			setDialogs(currentModals => currentModals.concat(dialogData));
		}
	}, [setDialogs]);

	useEffect(function beforeComponentRender() {
		const subscription = event.subscribe(onReceivedMessage);
		return function beforeComponentUnmount() {
			subscription.unsubscribe();
		}
	}, [onReceivedMessage, event]);

	const createDialog = useCallback(function createDialog(type) {
		switch (type) {
			case DialogBuilder.Type.CONFIRMATION:
				return ConfirmationDialog;
			case DialogBuilder.Type.SINGLE_ACTION:
				return SingleActionDialog;
			default: return null;
		}
	}, []);

	return (
		<div>
			{dialogs.map(dialog => {
				const Component = createDialog(dialog.type);
				return Component ? <Component key={dialog.id} {...dialog} /> : null;
			})}
		</div>
	)
}

DialogContainer.displayName = "DialogContainer";
export default DialogContainer;
