import { useCallback, useMemo } from "react";
import MessageBusTransactionType from "../constants/MessageBusTransactionType";
import MessageBuilder from "../package/MessageBuilder";
import useEvent from "./useEvent";

function usePageLoading() {
	const event = useEvent();

	const start = useCallback(function startLoading() {
		event.publish(
			MessageBuilder.builder().setType(MessageBusTransactionType.PAGE_LOAD_START)
		);
	}, [event]);

	const stop = useCallback(function stopLoading() {
		event.publish(
			MessageBuilder.builder().setType(MessageBusTransactionType.PAGE_LOAD_STOP)
		);
	}, [event]);

	return useMemo(() => ({ start, stop }), [start, stop]);
}

export default usePageLoading;