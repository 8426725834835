import React from "react";
import { Link } from "react-router-dom";
// Material.UI
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
// Models, libraries, hooks...
import { useStyles } from "../../../assets/styles/nav-menu.js";
import AppRoute from "../../../constants/AppRoute";

import { Typography } from "../../atom/Typography";
import { WorklogButton } from "../../organism/WorklogButton";
import { useSession } from "../../../hooks/useSession";
import { headerMenuData } from "../../../constants/HeaderMenu.js";
import withApplication from "../../hoc/withApplication.js";
import LinkSupport from "../LinkSupport/LinkSupport.jsx";

const icons = { SettingsOutlinedIcon, ExitToAppOutlinedIcon };

function NavMenu(props) {
	const classes = useStyles(props);
	const session = useSession();

	return (
		<Box className={classes.navMenu}>
			{typeof props.onlineCount === "number" && (
				<div className={classes.navMenuMapsOnlineUsers}>
					<FiberManualRecordIcon
						className={classes.navMenuMapsOnlineIcon}
						fontSize='small'
					/>
					{props.onlineCount} korisnika aktivno
				</div>
			)}
			<Grid
				container
				direction='row'
				className={classes.navMenuRow}
			>
				{props.children}
				<WorklogButton size="small" variant="text" color="default" />
				<LinkSupport className={classes.linkSupport} />
				{headerMenuData.map((menuItem) => {
					const Icon = icons[menuItem.icon];
					let itemPath = menuItem.path;
					if (menuItem.name === "podesavanja") {
						itemPath = AppRoute.PROFILE + "/" + session.getUserId();
					}
					return (
						<Grid
							item
							component={Link}
							key={menuItem.id}
							className={classes.navMenuItem}
							to={itemPath}
							onClick={menuItem.onClick}
						>
							<Box className={classes.navMenuIcon}>
								<Icon fontSize='small' color={menuItem.color} />
							</Box>
							<Box className={classes.navMenuText}>
								<Typography variant='subtitle2' color={menuItem.color}>
									{menuItem.caption}
								</Typography>
							</Box>
						</Grid>
					);
				})}
			</Grid>
		</Box>
	);
}

export default withApplication(NavMenu);
