import AppRoute from "./AppRoute";

export const SidebarMenuBlueprint = [
	{
		id: 1,
		caption: "Zadaci",
		icon: "StackPushIcon",
		path: AppRoute.JOBS,
	},
	{
		id: 2,
		caption: "Lokacije",
		icon: "LocationOnIcon",
		path: AppRoute.LOCATIONS,
	},
	{
		id: 3,
		caption: "Objekti",
		icon: "HomeIcon",
		path: AppRoute.BUILDING_SITES,
	},
	{
		id: 4,
		caption: "Zaposleni",
		icon: "UserIcon",
		path: AppRoute.USERS,
	},
	{
		id: 5,
		caption: "Izvođači",
		icon: "UserGroupIcon",
		path: AppRoute.CONTRACTORS,
	},
	{
		id: 6,
		caption: "Mape",
		icon: "MapIcon",
		path: AppRoute.MAPS
	}
];
