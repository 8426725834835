import Subject from "../package/Subject";

/**
 * Global services which advocates PUB-SUB design pattern used to transmit messages
 * across the application. This class implements singleton design pattern so it can be instantiated
 * only once on the first use.
 */
export default class EventService extends Subject {
	/**
	 * Test
	 * @returns {EventService}
	 */
	static getInstance() {
		return EventService.instance;
	}

	static async boot() {
		return new EventService();
	}

	constructor() {
		super();
		if (EventService.instance instanceof EventService) {
			return EventService.instance;
		}
		EventService.instance = this;
	}

	terminate() {
		this.reset();
		EventService.instance = null;
	}
}
