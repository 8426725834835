export default class DialogBuilder {
	static Type = {
		CONFIRMATION: "confirmation",
		SINGLE_ACTION: "single-action"
	}
	static builder() {
		return new DialogBuilder();
	}
	constructor(
		title = "",
		content = "",
		buttons = {
			submit: {
				text: "Ok",
				onSubmit: null
			},
			close: {
				text: "Cancel",
				onClose: null
			}
		},
		type = DialogBuilder.Type.SINGLE_ACTION
	) {
		this.title = title;
		this.content = content;
		this.submitButtonText = buttons.submit.text;
		this.closeButtonText = buttons.close.text;
		this.onSubmit = buttons.submit.onSubmit;
		this.onClose = buttons.close.onClose;
		this.type = type;
	}

	setTitle(title = "") {
		this.title = title;
		return this;
	}
	setContent(content = "") {
		this.content = content;
		return this;
	}
	setSubmitButtonText(submitButtonText = "") {
		this.submitButtonText = submitButtonText;
		return this;
	}
	setCloseButtonText(closeButtonText = "") {
		this.closeButtonText = closeButtonText;
		return this;
	}
	setOnSubmit(onSubmit) {
		this.onSubmit = onSubmit;
		return this;
	}
	setOnClose(onClose) {
		this.onClose = onClose;
		return this;
	}
	setType(type) {
		this.type = type;
		return this;
	}
	build() {
		return this;
	}
}