import React from "react";
import { useMediaQuery, useTheme } from "@material-ui/core";

import { Dialog } from "../../atom/Dialog";
import { DialogContent } from "../../atom/DialogContent";
import { DialogActions } from "../../atom/DialogActions";
import { Button } from "../../atom/Button";
import { isFunction } from "../../../util/assertions";

function SingleActionDialog(props) {
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
	const { onClose, onSubmit } = props;
	const canSubmit = isFunction(onSubmit);
	const canClose = isFunction(onClose);

	return (
		<Dialog open={props.open} fullScreen={fullScreen} onClose={canClose ? onClose : undefined}>
			<DialogContent>{props.content}</DialogContent>
			<DialogActions>
				{canSubmit && <Button onClick={onSubmit}>{props.submitButtonText}</Button>}
			</DialogActions>
		</Dialog>
	)
}

SingleActionDialog.displayName = "SingleActionDialog";
export default SingleActionDialog;
