import { useCallback, useEffect, useState } from "react";
import MessageBusTransactionType from "../constants/MessageBusTransactionType";
import { PageLoading } from "../components/molecule/PageLoading";
import useEvent from "../hooks/useEvent";

function PageLoadingContainer() {
	const [isLoading, setIsLoading] = useState(false);
	const event = useEvent();

	const onReceivedMessage = useCallback(function onReceivedMessage(transaction) {
		if (transaction.type === MessageBusTransactionType.PAGE_LOAD_START) {
			setIsLoading(true);
		}
		if (transaction.type === MessageBusTransactionType.PAGE_LOAD_STOP) {
			setIsLoading(false);
		}
	}, [setIsLoading]);

	useEffect(function beforeComponentRender() {
		const subscription = event.subscribe(onReceivedMessage);
		return function beforeComponentUnmount() {
			subscription.unsubscribe();
		}
	}, [onReceivedMessage, event]);

	return <PageLoading isLoading={isLoading} />
}

PageLoadingContainer.displayName = "PageLoadingContainer";
export default PageLoadingContainer;
