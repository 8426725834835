import React from "react";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { useClassNames } from "./ConfirmationDialog.styles";
import { Dialog } from "../../atom/Dialog";
import { DialogContent } from "../../atom/DialogContent";
import { DialogActions } from "../../atom/DialogActions";
import { Button } from "../../atom/Button";
import { isFunction } from "../../../util/assertions";

function ConfirmationDialog(props) {
	const { onClose, onSubmit } = props;
	const canSubmit = isFunction(onSubmit);
	const canClose = isFunction(onClose);
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
	const classes = useClassNames();

	return (
		<Dialog open={props.open} fullScreen={fullScreen} onClose={canClose ? onClose : undefined}>
			<DialogContent className={classes.content}>{props.content}</DialogContent>
			<DialogActions>
				{canClose && <Button onClick={onClose} color="default" variant="contained">{props.closeButtonText}</Button>}
				{canSubmit && <Button onClick={onSubmit}>{props.submitButtonText}</Button>}
			</DialogActions>
		</Dialog>
	)
}

ConfirmationDialog.displayName = "ConfirmationDialog";
export default ConfirmationDialog;
