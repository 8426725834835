import SessionService from "../../services/SessionService";
import { sendRequest } from "./sendRequest";

/**
 * Sends HTTPs Request with Bearer authorization token.
 * @param {Request} request
 * @returns {Promise<Response>}
 */
export async function sendAuthenticatedRequest(request) {
	if (!request.headers.has("Authorization")) {
		const session = SessionService.getInstance();
		if (session && session.refreshToken) {
			if (session.isAuthenticated() && session.isExpired()) {
				await session.refresh();
			}
			request.headers.set("Authorization", `Bearer ${session.accessToken}`);
		}
	}
	return sendRequest(request);
}
