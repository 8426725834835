import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";

const theme = createMuiTheme({
	overrides: {
		MuiCssBaseline: {
			"@global": {
				a: {
					textDecoration: "none",
					color: "inherit",
				},
			},
		},
	},
	breakpoints: {
		values: {
			xs: 0,
			sm: 601,
			md: 961,
			lg: 1367,
			xl: 1921,
		},
	},
	palette: {
		primary: {
			dark: "#e60000",
			main: "#FF3E3E",
			light: "#ff6565"
		},
		text: {
			primary: "#474747"
		}
	},
	typography: {
		fontFamily: ['"Montserrat"', 'sans-serif'].join(','),
		subtitle2: {
			fontSize: 14,
			"@media screen and (min-width: 1281px)": {
				fontSize: 16
			}
		},
		body1: {
			fontSize: 14,
			"@media screen and (min-width: 1281px)": {
				fontSize: 16
			}
		},
		button: {
			fontSize: 14,
			"@media screen and (min-width: 1281px)": {
				fontSize: 16
			}
		}
	},
});

export default responsiveFontSizes(theme);
