import EmployeeStatusDAO from "api/dao/EmployeeStatusDAO";
import UserDAO from "api/dao/UserDAO";
import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from "react";
import { getResponseBody, getValidResponseBody } from "util/api";

const EmployeeStatusContext = createContext([]);

export function useEmployeeStatus() {
	return useContext(EmployeeStatusContext);
}

function EmployeeStatusContainer(props) {
	const [statuses, setStatuses] = useState([]);

	const fetched = useRef(false);

	const fetchEmployeeStatuses = useCallback(async () => {
		if (!fetched.current) {
			const response = await EmployeeStatusDAO.query().list();
			const body = await getResponseBody(response);
			const content = body.content;
			setStatuses(content);
			fetched.current = true;
		}
	}, []);

	useEffect(fetchEmployeeStatuses, [
		fetchEmployeeStatuses
	]);

	const changeStatus = useCallback(async function changeEmployeeStatus(userId, statusId) {
		const response = await UserDAO.query()
			.changeEmployeeStatus(userId, statuses.find(status => status.id === statusId));
		return getValidResponseBody(response);
	}, [statuses]);

	function getProviderValue() {
		return {
			statuses,
			changeStatus
		}
	}

	return (
		<EmployeeStatusContext.Provider value={getProviderValue()}>
			{props.children}
		</EmployeeStatusContext.Provider>
	)
}

EmployeeStatusContainer.displayName = "EmployeeStatusContainer";
export default EmployeeStatusContainer;
