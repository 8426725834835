export { default as SearchIcon } from "@material-ui/icons/Search";
export { default as CloseIcon } from "@material-ui/icons/Close";
export { default as EditIcon } from "@material-ui/icons/EditOutlined";
export { default as DeleteIcon } from "@material-ui/icons/DeleteOutlined";
export { default as SettingsIcon } from "@material-ui/icons/Settings";
export { default as LogoutIcon } from "@material-ui/icons/ExitToApp";
export { default as AttachFileIcon } from "@material-ui/icons/AttachFile";
export { default as LocationOnIcon } from "@material-ui/icons/LocationOnOutlined";
export { default as LocationOnFilledIcon } from "@material-ui/icons/LocationOn";
export { default as ArrowBackIcon } from "@material-ui/icons/ArrowBack";
export { default as DownloadIcon } from "@material-ui/icons/GetApp";
export { default as MapIcon } from "@material-ui/icons/MapOutlined";
export { default as ExpandIcon } from "@material-ui/icons/ExpandMore";
export { default as VisibilityIcon } from '@material-ui/icons/Visibility';
export { default as DescriptionIcon } from '@material-ui/icons/Description';
export { default as UserIcon } from "@material-ui/icons/PersonOutlineOutlined";
export { default as HomeIcon } from "@material-ui/icons/HomeOutlined";
export { default as UserGroupIcon } from "@material-ui/icons/GroupOutlined";
export { default as StackPushIcon } from "@material-ui/icons/LibraryAddOutlined";
export { default as CameraIcon } from "@material-ui/icons/PhotoCameraOutlined";
export { default as FilePreviewIcon } from '@material-ui/icons/FindInPageOutlined';
export { default as VisibilityOffIcon } from "@material-ui/icons/VisibilityOff";
