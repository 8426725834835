import UserRole from "../constants/UserRole";
import { hasRole } from "./assertions";
import { curry, pluck } from "./functions";

// Shortcut utilities.
export const pluckContent = curry(pluck, "content");
export const pluckId = curry(pluck, "id");
export const hasAdminRole = curry(hasRole, UserRole.ADMIN);
export const createUniqueArrayById = curry(createUniqueArrayBy, "id");
export const whereEqual = curry((left, right) => left === right);
export const whereNotEqual = curry((left, right) => left !== right);
export function createUniqueArrayBy(property, array) {
	const seen = new Set();
	return array.filter(object => {
		const value = pluck(property, object);
		if (!seen.has(value)) {
			seen.add(value);
			return true;
		}
		return false;
	});
}
