import { isNumber, isObject, isString, isResponse } from "./assertions";
import { pipe } from "./functions";

export function getContentTypeHeader(requestBody) {
	if (requestBody === null || requestBody === undefined) {
		return null;
	}

	if ([FormData, ArrayBuffer].some(instance => {
		return requestBody instanceof instance;
	})) {
		return null;
	}

	if (requestBody instanceof Blob) {
		return requestBody.type || null;
	}

	const jsonContentType = "application/json;charset=UTF-8";
	const textContentType = "text/plain;charset=UTF-8";
	const urlEncodedContentType = "application/x-www-form-urlencoded;charset=UTF-8";

	if (isString(requestBody)) {
		try {
			JSON.parse(requestBody);
			return jsonContentType;
		} catch {
			return textContentType;
		}
	}

	if (requestBody instanceof URLSearchParams) {
		return urlEncodedContentType;
	}

	if (isNumber(requestBody) || Array.isArray(requestBody) || isObject(requestBody)) {
		return jsonContentType;
	}

	return null;
}

/**
 * Extract response body based on the content type header.
 * @param {Response} response
 */
export function getResponseBody(response) {
	if (!isResponse(response)) {
		return null;
	}
	const type = response.headers.get("content-type").split(";")[0];
	switch (type) {
		case "application/json":
			return response.json();
		case "text/plain":
			return response.text();
		case "image/jpeg":
		case "image/png":
		case "audio/mpeg":
			return response.blob();
		default: return null;
	}
}

/**
 * Assert whether response is successful or not.
 * @param {Response} response
 * @returns {Response}
 * @throws {NetworkError}
 */
export function validateResponse(response) {
	if (!isResponse(response)) {
		throw new Error("Provided input is not instance of Response");
	}
	if (!response.ok) {
		const error = new Error("Odgovor servera nije validan.");
		error.response = response;
		throw error;
	}
	return response;
}

export const getValidResponseBody = pipe(validateResponse, getResponseBody);
