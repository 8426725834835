import React, { useCallback, useMemo } from "react";
import { useHistory } from "react-router";
import { useClassNames } from "./SidebarNavigationMenu.styles";
import { Hidden } from "@material-ui/core";
import { Button } from "../../atom/Button";
import { SidebarMenuBlueprint } from "../../../constants/SidebarMenu";
import AppRoute from "../../../constants/AppRoute";
import {
	MapIcon,
	LocationOnIcon,
	UserIcon,
	HomeIcon,
	UserGroupIcon,
	StackPushIcon
} from "../../atom/Icon";

function SidebarNavigationMenu(props) {
	const classes = useClassNames();
	const { push, location } = useHistory();

	const navigateToThePath = useCallback(function navigateToThePath(event, { path }) {
		push(path);
	}, [push]);

	const activePathMap = useMemo(() => new Map([
		[AppRoute.JOBS, [AppRoute.JOBS, AppRoute.JOB]],
		[AppRoute.LOCATIONS, [AppRoute.LOCATIONS, AppRoute.LOCATION]],
		[AppRoute.BUILDING_SITES, [AppRoute.BUILDING_SITES, AppRoute.BUILDING_SITE]],
		[AppRoute.USERS, [AppRoute.USERS, AppRoute.USER]],
		[AppRoute.CONTRACTORS, [AppRoute.CONTRACTORS, AppRoute.CONTRACTOR]],
		[AppRoute.MAPS, [AppRoute.MAPS]]
	]), []);

	const isActiveMenuPath = useCallback((path) => {
		const paths = activePathMap.get(path);
		if (!paths) {
			return false;
		}
		if (!location.pathname) {
			return false;
		}
		if (location.pathname === "/" && path !== AppRoute.JOBS) {
			return false;
		}
		return Boolean(paths.find(possiblePath => possiblePath.includes(location.pathname)));
	}, [activePathMap, location]);

	const retrieveMenuIcon = useCallback(function retrieveMenuIcon(iconName) {
		return {
			MapIcon,
			LocationOnIcon,
			UserIcon,
			HomeIcon,
			UserGroupIcon,
			StackPushIcon
		}[iconName];
	}, []);

	return (
		<nav className={classes.nav}>
			{SidebarMenuBlueprint.map(menu => {
				const Icon = retrieveMenuIcon(menu.icon);
				return (
					<Button
						key={menu.id}
						path={menu.path}
						onClick={navigateToThePath}
						className={isActiveMenuPath(menu.path) ? "active" : undefined}
					>
						<Hidden lgUp>
							{Boolean(Icon) && <Icon fontSize='large' />}
						</Hidden>
						<Hidden mdDown>
							{Boolean(Icon) && <Icon className={classes.icon} />} <span>{menu.caption}</span>
						</Hidden>
					</Button>
				)
			})}
		</nav>
	)
}

SidebarNavigationMenu.displayName = "SidebarNavigationMenu";
export default SidebarNavigationMenu;
