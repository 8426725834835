import React, { memo, useCallback, useEffect, useLayoutEffect, useState } from "react";
import NetworkError from "../api/package/NetworkError";
import NetworkErrorScreen from "../components/NetworkErrorScreen";
import useEvent from "../hooks/useEvent";

function NetworkErrorContainer(props) {
	const [error, setError] = useState(null);
	const [isMounted, setIsMounted] = useState(false);
	const event = useEvent();

	const onReceivedMessage = useCallback(function onReceivedMessage(error) {
		if (error instanceof NetworkError) {
			setError(error);
		}
	}, [setError]);

	useEffect(function beforeComponentRender() {
		const subscription = event.subscribe(onReceivedMessage);
		return function beforeComponentUnmount() {
			subscription.unsubscribe();
		}
	}, [event, onReceivedMessage]);

	useLayoutEffect(() => {
		setIsMounted(true);
	}, []);

	if (!isMounted) {
		return null;
	}

	if (!error) {
		return props.children;
	}

	return (
		<NetworkErrorScreen
			code={error.code}
			title={error.title}
			message={error.message}
			actionButtonText={error.recoverText}
			onActionButtonClick={error.recoverCallback}
		/>
	);
}

NetworkErrorContainer.displayName = "NetworkErrorContainer";
export default memo(NetworkErrorContainer);
