import { LinkExternal } from "components/atom/LinkExternal";
import { SUPPORT_EMAIL } from "constants/backend";
import React from "react";

function LinkSupport(props) {
	const {
		children,
		className
	} = props;
	return (
		<LinkExternal className={className} to={`mailto:${SUPPORT_EMAIL}`}>
			{children}
		</LinkExternal>
	)
}

LinkSupport.defaultProps = {
	children: "Podrška"
}
LinkSupport.displayName = "LinkSupport";
export default LinkSupport;
