import React from "react";
import { Alert as AlertCore } from "@material-ui/lab";

function Alert(props) {
	return (
		<AlertCore {...props} data-testid={props.testId} />
	)
}

Alert.displayName = "Alert";
export default Alert;
