import logout from "../api/network/logout";
import AppRoute from "../constants/AppRoute.js";
import { noop } from "../util/functions";

export const headerMenuData = [
	{
		id: 1,
		name: "podesavanja",
		caption: "Profil",
		icon: "SettingsOutlinedIcon",
		color: "inherit",
		path: AppRoute.PROFILE,
		onClick: noop,
	},
	{
		id: 2,
		name: "odjava",
		caption: "Odjavi se",
		icon: "ExitToAppOutlinedIcon",
		color: "primary",
		path: "#",
		onClick: logout,
	},
];
