import React from "react";
import { DialogActions as DialogActionsCore } from "@material-ui/core";

/**
 * Facade component abstracting 3rd party DialogActions component
 * exposing only used properties to the application developer.
 * @param {{
 *  className?: string;
 *  children?: React.ReactNode;
 * }} props 
 * @returns 
 */
function DialogActions(props) {
  return (
    <DialogActionsCore
      className={props.className}
    >
      {props.children}
    </DialogActionsCore>
  )
}

DialogActions.defaultProps = {};
DialogActions.displayName = "DialogActions";
export default DialogActions;