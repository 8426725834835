import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
	container: {
		width: "100%",
		height: "100%",
		background: "transparent radial-gradient(closest-side at 50% 50%, #FFFFFF 0%, #E6E6E6 100%) 0% 0% no-repeat padding-box",
		margin: 0
	},
	content: {
		color: "#464646",
		textAlign: "center",
		marginTop: theme.spacing(4)
	},
	actionButton: {
		marginTop: theme.spacing(4)
	}
}));