import RequestBuilder from "../../../package/RequestBuilder";
import { isInteger, isString } from "../../../util/assertions";
import { sendAuthenticatedRequest } from "../../network/sendAuthenticatedRequest";

class DAO {
	constructor(endpoint) {
		this.endpoint = endpoint;
		this.request = RequestBuilder.builder();
		this.list = this.list.bind(this);
		this.find = this.find.bind(this);
		this.limit = this.limit.bind(this);
		this.size = this.limit;
		this.page = this.page.bind(this);
		this.offset = this.page;
		this.create = this.create.bind(this);
		this.update = this.update.bind(this);
		this.delete = this.delete.bind(this);
	}
	/**
	 * Sends request to the API to retrieve list of results.
	 * @returns {Promise<Response>}
	 * @example
	 * const response = DAO.list();
	 */
	list() {
		return sendAuthenticatedRequest(this.request.setURL(this.endpoint).build())
	}
	/**
	 * Send request to the API seeking for the single resource or the resource
	 * which matches provided criteria.
	 * @param {number|string|{[name: string]: string}} arg0
	 * @returns {Promise<Response>}
	 * @throws {SyntaxError}
	 * @example
	 * const response = await DAO.find(42);
	 * @example
	 * const response = await DAO.find({name: "stefan"});
	 */
	find(arg0) {
		if (!arg0) {
			return Promise.reject("Provided argument is invalid.");
		}
		if (typeof arg0 === "number" || typeof arg0 === "string") {
			return sendAuthenticatedRequest(
				this.request
					.setURL(this.endpoint + "/" + arg0)
					.build()
			);
		}
		if (Object.prototype.toString.call(arg0) === "[object Object]" && !Array.isArray(arg0)) {
			this.request.setParams(arg0)
		}
		return sendAuthenticatedRequest(this.request.setURL(this.endpoint + "/search").build());
	}
	/**
	 * In case API supports page size limitation, configure parameter for collection length.
	 * @param {number|string} limit
	 * @returns {this}
	 * @example
	 * const response = DAO.limit(10).list();
	 */
	limit(limit) {
		this.request.where("pageSize", limit);
		return this;
	}
	/**
	 * Configure response sorting.
	 * @param {string} columnName
	 * @param {typeof import("@material-ui/core").SortDirection} direction
	 * @returns {this}
	 */
	sortBy(columnName, direction) {
		this.request.where("sortBy", columnName);
		this.request.where("direction", direction);
		return this;
	}
	/**
	 * Configure current page of the request.
	 * @param {number|string} currentPage
	 * @returns {this}
	 */
	page(currentPage) {
		this.request.where("pageNo", currentPage);
		return this;
	}
	/**
	 *
	 * @param {string} date
	 * @returns
	 */
	from(date) {
		if (isString(date)) {
			this.request.where("fromDate", date);
		}
		return this;
	}
	/**
	 *
	 * @param {string} date
	 * @returns
	 */
	to(date) {
		if (isString(date)) {
			this.request.where("toDate", date);
		}
		return this;
	}
	/**
	 * Sends request to the API attempting to insert new record into the database.
	 * @param {*} model
	 * @returns {Promise<Response>}
	 */
	create(model) {
		return sendAuthenticatedRequest(this.request
			.setURL(this.endpoint)
			.setMethod("POST")
			.setBody(model)
			.build());
	}
	/**
	 * Sends request to the API attempting to replace record in the database.
	 * @param {*} model
	 * @returns
	 */
	update(model) {
		return sendAuthenticatedRequest(
			this.request
				.setURL(this.endpoint + "/" + model.id)
				.setMethod("PUT")
				.setBody(model)
				.build()
		);
	}
	/**
	 * Sends request to the API attempting to remove record from the database.
	 * @param {number|string} id
	 * @returns {Promise<Response>}
	 */
	delete(id) {
		id = isString(id) ? Number(id) : id;
		if (!isInteger(id)) {
			return Promise.reject({message: `id (${id}) is not an integer nor string like integer.`});
		}
		if (id < 1) {
			return Promise.reject({message: `id (${id}) cannot be a negative number.`});
		}
		return sendAuthenticatedRequest(
			this.request
				.setURL(this.endpoint + "/" + id)
				.setMethod("DELETE")
				.build()
		);
	}
}

export default DAO;
