const SerbianLocalization = {
	operation: {
		created: "Zapis je uspešno sačuvan.",
		updated: "Zapis je uspešno ažuriran.",
		deleted: "Zapis je uspešno obrisan.",
		created_or_updated: "Zapis je uspešno {{action}}.",
		loaded_error: "Došlo je do greške prilikom pokušaja učitavanja zapisa.",
		searched_error: "Došlo je do greške prilikom pretrage zapisa.",
		created_error: "Došlo je do greške prilikom čuvanja zapisa.",
		updated_error: "Došlo je do greške prilikom ažuriranja zapisa.",
		created_or_updated_error: "Došlo je do greške prilikom {{action}} zapisa.",
		deleted_error: "Došlo je do greške prilikom brisanja zapisa.",
		error: {
			title: "Greška"
		},
		success: {
			title: "Operacija uspešno izvršena"
		},
		actionButtonText: "Ok",
		closeButtonText: "Poništi",
		licence: {
			activated: "Licenca je uspešno aktivirana.",
			suspended: "Licenca je uspešno suspendovana.",
			error: "Došlo je do greške prilikom promene statusa licence."
		}
	},
	form: {
		button: {
			save: "Sačuvaj",
			update: "Ažuriraj",
			delete: "Obriši",
			fetch: "Učitaj",
			refresh: "Osveži",
			cancel: "Poništi",
			add: "Dodaj",
		}
	},
	check_in: {
		success: "Uspešno ste započeli radni dan!",
		error: "Došlo je do greške prilikom započinjanja radnog dana. Molimo pokušajte ponovo."
	},
	check_out: {
		success: "Uspešno ste završili radni dan!",
		error: "Došlo je do greške prilikom završavanja radnog dana. Molimo pokušajte ponovo."
	},
}

export default SerbianLocalization;
