import React from "react";
import {Dialog} from "../../atom/Dialog";
import {DialogContent} from "../../atom/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import CircularProgress from "@material-ui/core/CircularProgress";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { useClassNames } from "./PageLoading.styles";

/**
 *
 * @param {{
 *  isLoading?: boolean;
 *  content?: string;
 * }} props
 * @returns
 */
function PageLoading(props) {
	const classes = useClassNames();
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

	return (
		<Dialog fullScreen={fullScreen} open={props.isLoading}>
			<DialogContent className={classes.loadingContent}>
				<DialogContentText>{props.content}</DialogContentText>
				<CircularProgress />
			</DialogContent>
		</Dialog>
	);
};

PageLoading.defaultProps = {
	content: "Učitavanje"
}
PageLoading.displayName = "PageLoading";
export default PageLoading;
