export const EMAIL_REGEX = /^[A-Z0-9._%-]+(\+\d+)?@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
export const DECIMAL_REGEX = /^[1-9]\d*(\.\d+)?$/;
export const PASSWORD_REGEX = /^(?=.*[\d])(?=.*[a-zčćšđž]).{6,}$/i;
export const PHONE_REGEX = /^\+?\d{8,16}$/;
export const NAME_REGEX = /^[a-zčćđšž\u0400-\u04FF]+$/i; // Validate english alphabet and cyrillic.
export const LAST_NAME_REGEX = /^(([a-zčćđšž\u0400-\u04FF])+)\s?(([a-zčćđšž\u0400-\u04FF])+)$/i // Validate english alphabet and cyrillic.
export const DATE_ISO_REGEX = /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/;

export const ISO_PATTERN = "YYYY-MM-DDTHH:mm:ss.sssZ";
export const MATERIAL_UI_DATE_PATTERN = "YYYY-MM-DD";
