import { BACKEND_API_URL, BACKEND_OAUTH_URL } from "./backend";

export default class ApiEndpoint {
	static API_BASE = BACKEND_API_URL;
	static API_AUTH_BASE = BACKEND_OAUTH_URL;
	static ACCESS_ROLES = ApiEndpoint.API_BASE + "/accessRoles";
	static AUTHORIZATION = ApiEndpoint.API_AUTH_BASE + "/authorize";
	static ADDITIONAL_FILTERS = ApiEndpoint.API_BASE + "/additionalFilters";
	static ERROR = "https://myplanner.quantox.dev/error";
	static BUILDING_SITES = ApiEndpoint.API_BASE + "/buildingSites";
	static CHECK_IN = ApiEndpoint.API_BASE + "/checkIn";
	static CHECK_OUT = ApiEndpoint.API_BASE + "/checkOut";
	static CHECK_TOKEN = ApiEndpoint.API_AUTH_BASE + "/check_token";
	static CONTRACTORS = ApiEndpoint.API_BASE + "/contractors";
	static EMPLOYEE_STATUS = ApiEndpoint.API_BASE + "/employeeStatus";
	static JOB_COMMENTS = ApiEndpoint.API_BASE + "/jobComments";
	static JOBS = ApiEndpoint.API_BASE + "/jobs";
	static JOB_DOCUMENTS = ApiEndpoint.API_BASE + "/jobDocuments";
	static JOB_FILTERS = ApiEndpoint.API_BASE + "/jobFilters";
	static LOCATIONS = ApiEndpoint.API_BASE + "/locations";
	static OPERATION = "https://myplanner.quantox.dev/actuator";
	static PERMISSIONS = ApiEndpoint.API_BASE + "/permissions";
	static PROFESSIONS = ApiEndpoint.API_BASE + "/professions";
	static RIGHTS = ApiEndpoint.API_BASE + "/rights";
	static SECTIONS = ApiEndpoint.API_BASE + "/sections";
	static STATUSES = ApiEndpoint.API_BASE + "/status";
	static TEAMS = ApiEndpoint.API_BASE + "/teams";
	static TOKEN = ApiEndpoint.API_AUTH_BASE + "/token";
	static TOKEN_KEY = ApiEndpoint.API_AUTH_BASE + "/token_key";
	static USERS = ApiEndpoint.API_BASE + "/users";
	static USER_LOCATIONS = ApiEndpoint.API_BASE + "/userLocations";
	static USER_STATUSES = ApiEndpoint.API_BASE + "/userStatus";
	static USER_TEAMS = ApiEndpoint.API_BASE + "/userTeams";
	static WHITE_LABEL_APPROVAL = ApiEndpoint.API_AUTH_BASE + "/confirm_access";
	static WHITE_LABEL_ERROR = ApiEndpoint.API_AUTH_BASE + "/error";
	static WORKLOG = ApiEndpoint.API_BASE + "/worklog";
	static PREFERENCES = ApiEndpoint.API_BASE + "/preferences";
	static REPORTS = ApiEndpoint.API_BASE + "/reports";
}
