import { memo } from "react";
import { useClassNames } from "../../assets/styles/layout";
import NavMenu from "../molecule/NavMenu/NavMenu";
import DefaultLayout from "../template/Default";
import { Sidebar } from "../organism/Sidebar";
import NetworkErrorContainer from "../../containers/NetworkErrorContainer";
import { APP_VERSION } from "../../constants/backend";

function withPageLayout(Component) {
	function PageLayout(props) {
		const classes = useClassNames();

		return (
			<DefaultLayout>
				<Sidebar />
				<main className={classes.main}>
					<div className={classes.navMenuContainer}>
						<NavMenu />
					</div>
					<NetworkErrorContainer>
						<Component {...props} />
					</NetworkErrorContainer>
					<footer className={classes.footer}>
						<small>Build version: {APP_VERSION}</small>
					</footer>
				</main>
			</DefaultLayout>
		)
	};

	PageLayout.displayName = "PageLayout";
	return memo(PageLayout);
}

export default withPageLayout;
