import ApiEndpoint from "../../constants/ApiEndpoint";
import { DAO } from "../package/DAO";

class EmployeeStatusDAO extends DAO {
	static query() {
		return new EmployeeStatusDAO();
	}
	constructor() {
		super(ApiEndpoint.EMPLOYEE_STATUS);
	}
}

export default EmployeeStatusDAO;
