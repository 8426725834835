export default class AppRoute {
	static HOME = "/";
	static JOBS = "/zadaci";
	static JOB = "/zadatak";
	static COMMENT = "/komentar";
	static LOCATIONS = "/lokacije";
	static LOCATION = "/lokacija";
	static BUILDING_SITES = "/objekti";
	static BUILDING_SITE = "/objekat";
	static USERS = "/svi-zaposleni";
	static USER = "/zaposleni";
	static CONTRACTORS = "/izvodjaci";
	static CONTRACTOR = "/izvodjac";
	static LOGIN = "/prijava";
	static LOGOUT = "/odjava";
	static PROFILE = "/profil";
	static MAPS = "/mapa";
	static PWD_RESET = "/lozinka/nova-lozinka";
	static PRIVACY_POLICY = "/legal/privacy_policy";
	static TERMS_AND_CONDITIONS = "/legal/terms_and_conditions";
}
