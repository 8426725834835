import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
	sidebarAvatarItem: {
		display: "flex",
		justifyContent: "center",
	},
	sidebarAvatarImage: {
		width: "100px !important",
		height: "100px !important",
		borderRadius: "50%",
		[theme.breakpoints.down("md")]: {
			width: "50px !important",
			height: "50px !important",
		},
	},
	profilePictureContainer: {
		boxSizing: "border-box",
		position: "relative"
	},
	status: {
		position: "absolute",
		display: "block",
		bottom: 0,
		right: 0,
		width: 15,
		height: 15,
		border: `2px solid ${theme.palette.primary.main}`,
		backgroundColor: green[400],
		borderRadius: "50%",
		[theme.breakpoints.up("lg")]: {
			width: 20,
			height: 20,
			right: 10
		}
	}
}));
