import { useEffect } from "react";
import SessionService from "../services/SessionService";
import useForceRefresh from "./useForceRefresh";

export function useSession() {
	const forceRefresh = useForceRefresh();

	useEffect(() => {
		const session = SessionService.getInstance();
		let subscription;
		if (session) {
			subscription = session.subscribe(forceRefresh);
		}
		return () => {
			if (subscription) {
				subscription.unsubscribe();
			}
		}
	}, [forceRefresh]);

	return SessionService.getInstance();
}
