import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import Container from "@material-ui/core/Container";
import { useStyles } from "../../assets/styles/login";
import logo from "../../assets/images/logo.png";
import AppRoute from "../../constants/AppRoute";
import { ForgotPasswordForm } from "../organism/ForgotPasswordForm";
import useApplication from "../../hooks/useApplication";

function PasswordResetPage(props) {
	const classes = useStyles();
	const app = useApplication();

	const onSuccessfulSubmit = useCallback(function () {
		app.showSuccessToast("Vaš zahtev je uspešno prihvaćen, molimo vas proverite vaše email sanduče.");
	}, [app]);

	const onErrorSubmit = useCallback(function () {
		app.showErrorToast("Došlo je do greške prilikom slanja zahteva.");
	}, [app]);

	return (
		<Container className={classes.loginContainer}>
			<div className={classes.loginCard}>
				<div>
					<div className={classes.loginLogo}>
						<img src={logo} alt='company-logo' />
					</div>
					<ForgotPasswordForm onSubmit={onSuccessfulSubmit} onError={onErrorSubmit} />
					<div className={classes.centeredLink}>
						<Link to={AppRoute.LOGIN}>Nazad na prijavu</Link>
					</div>
				</div>
			</div>
		</Container>
	)
}

PasswordResetPage.displayName = "PasswordResetPage";
export default PasswordResetPage;
