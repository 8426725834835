import React, { useCallback, useEffect, useState } from "react";
import { Snackbar } from "@material-ui/core";
import { Alert } from "../components/atom/Alert";
import { TransactionType } from "../constants/Transaction";
import useEvent from "../hooks/useEvent";

function ToastContainer(props) {
	const [toast, setToast] = useState(null);
	const event = useEvent();

	const handleClose = useCallback(function handleToastClose() {
		setToast(null);
	}, []);

	const onReceivedMessage = useCallback(function onReceivedMessage(transaction) {
		if (transaction.type === TransactionType.TOAST) {
			setToast(transaction.payload);
		}
	}, []);

	useEffect(() => {
		const subscription = event.subscribe(onReceivedMessage);
		return () => {
			subscription.unsubscribe();
		}
	}, [event, onReceivedMessage]);

	if (!toast) {
		return null;
	}

	return (
		<Snackbar
			open={Boolean(toast)}
			autoHideDuration={4000}
			onClose={handleClose}
			anchorOrigin={{vertical: "bottom", horizontal: "center"}}
			key="topcenter"
		>
			<Alert onClose={handleClose} severity={toast.toastType} variant="filled">
				{toast.message}
			</Alert>
		</Snackbar>
	)
}

ToastContainer.displayName = "ToastContainer";
export default ToastContainer;
