import React from "react";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";

import { useClassNames } from "./Logo.styles";
import logo from "../../../assets/images/logo-light.png";
import logoSmall from "../../../assets/images/logo-light-small.png";

function Logo(props) {
  const classes = useClassNames();
  return (
    <Box className={classes.logo} marginTop={2}>
      <Grid container spacing={2}>
        <Grid item className={classes.logoItem} xs={12}>
					<Hidden mdDown>
						<img src={logo} alt='logo' className={classes.logoImage} />
					</Hidden>
          <Hidden lgUp>
            <img
              src={logoSmall}
              alt='logo-small'
              className={classes.logoImageSmall}
            />
          </Hidden>
        </Grid>
      </Grid>
    </Box>
  );
};

Logo.displayName = "Logo";
export default Logo;
