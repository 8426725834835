import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
	container: {
		display: "block",
		position: "relative",
		"& .MuiOutlinedInput-input": {
			paddingRight: 40
		}
	},
	showButton: {
		appearance: "none",
		background: "transparent",
		cursor: "pointer",
		border: 0,
		position: "absolute",
		right: 5,
		top: "50%",
		transform: "translateY(-50%)"
	}
}));
