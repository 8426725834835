import React, { useMemo } from "react";
import { TextField as TextFieldCore } from "@material-ui/core";

/**
 * Facade component abstracting TextField/TextInput 3rd party component
 * exposing only used properties to the application developer.
 * @param {{
 *  id?: string;
 *  label?: string;
 *  placeholder?: string;
 *  variant?: "filled" | "outlined";
 *  value?: string;
 *  defaultValue?: string;
 *  className?: string;
 *  name?: string;
 *  helperText?: string;
 *  fullWidth?: boolean;
 * }} props
 * @returns
 */
function TextField(props) {
	const inputProps = useMemo(() => ({
		readOnly: props.readOnly,
		type: props.type,
		...props.inputProps
	}), [props.readOnly, props.inputProps, props.type]);

	return (
		<TextFieldCore
			inputRef={props.inputRef}
			id={props.id}
			label={props.label}
			placeholder={props.placeholder}
			variant={props.variant}
			defaultValue={props.defaultValue}
			value={props.value}
			className={props.className}
			name={props.name}
			onChange={props.onChange}
			error={props.error}
			helperText={props.helperText}
			fullWidth={props.fullWidth}
			InputProps={inputProps}
			disabled={props.disabled}
		/>
	);
}

TextField.defaultProps = {
	variant: "outlined",
};
TextField.displayName = "TextField";
export default TextField;
