import React from "react";
import { Dialog as DialogCore } from "@material-ui/core";

/**
 * Facade component abstracting 3rd party Dialog component
 * exposing only used properties to the application developer.
 * @param {{
 *  open?: boolean;
 *  className?: string;
 *  children?: React.ReactNode;
 *  onClose?: (event: {}, reason: "backdropClick" | "escapeKeyDown") => void;
 * }} props 
 * @returns 
 */
function Dialog(props) {
  return (
    <DialogCore
      open={props.open}
      className={props.className}
      onClose={props.onClose}
    >
      {props.children}
    </DialogCore>
  )
}

Dialog.defaultProps = {};
Dialog.displayName = "Dialog";
export default Dialog;