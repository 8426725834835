import React, { useEffect, useState } from "react";
import ApplicationService from "../../services/ApplicationService";
import { PageLoading } from "../molecule/PageLoading";

function withApplication(WrappedComponent) {
	function ApplicationBootstrap(props) {
		const [loaded, setLoaded] = useState(ApplicationService.booted);
		const [error, setError] = useState("");

		useEffect(function beforeComponentRender() {
			if (!loaded) {
				console.info("Loading application service..");
				ApplicationService.boot()
					.then(() => setLoaded(true))
					.catch(error => setError(error))
			}
		}, [loaded]);

		if (error) {
			console.error(error);
		}

		if (!loaded || error) {
			return <PageLoading isLoading={true} />
		}

		return <WrappedComponent {...props} />
	}

	ApplicationBootstrap.displayName = "ApplicationBootstrap";
	return ApplicationBootstrap;
}

export default withApplication;
