import { makeStyles } from "@material-ui/core/styles";

export const useClassNames = makeStyles((theme) => ({
	nav: {
		display: "flex",
		flexDirection: "column",
		"& > *": {
			display: "flex",
			justifyContent: "flex-start",
			letterSpacing: 1,
			padding: theme.spacing(2),
			backgroundColor: theme.palette.primary.light,
			textTransform: "uppercase",
			"&.active": {
				backgroundColor: theme.palette.primary.dark
			},
			[theme.breakpoints.down("md")]: {
				justifyContent: "center",
			},
		},
		"& > *:not(:last-child)": {
			marginBottom: theme.spacing(2),
		}
	},
	icon: {
		marginRight: theme.spacing(1)
	},
}));
