import { useCallback } from "react";
import { isFunction } from "../../../util/assertions";
import { Button } from "../../atom/Button";

/**
 *
 * @param {{
 *  testId?: string;
 *  className?: string;
 *  activeLabel?: string;
 *  inactiveLabel?: string;
 *  state?: "active" | "inactive";
 *  onChange?: ({ state: "active" | "inactive" }) => void;
 * }} props
 * @returns
 */
function ButtonSwitch(props) {
	const {
		testId,
		className,
		activeLabel,
		inactiveLabel,
		state,
		onChange,
		size,
		variant,
		color
	} = props;

	const renderLabel = useCallback(function renderButtonSwitchLabel() {
		switch (state) {
			case "active": return activeLabel;
			default: return inactiveLabel;
		}
	}, [
		activeLabel,
		inactiveLabel,
		state
	]);

	const processStateChange = useCallback(function processStateChange() {
		onChange({ nextState: state === "active" ? "inactive" : "active" });
	}, [state, onChange]);

	return (
		<Button
			testId={testId}
			className={className}
			onClick={isFunction(onChange) ? processStateChange : undefined}
			size={size}
			variant={variant}
			color={color}
		>{renderLabel()}</Button>
	);
}

ButtonSwitch.defaultProps = {
	state: "inactive"
}
ButtonSwitch.displayName = "ButtonSwitch";
export default ButtonSwitch;
