import { whereEqual } from "../util";
import { isFunction } from "../util/assertions";
import { once } from "../util/functions";

export default class Subject {
	constructor() {
		this.subscribe = this.subscribe.bind(this);
		this.unsubscribe = this.unsubscribe.bind(this);
		this.reset = this.reset.bind(this);
		this.reset();
	}

	subscribe(observer) {
		if (!isFunction(observer)) {
			throw new Error("Provided observer is not a function.");
		}
		const index = this.observers.length;
		this.observers.push(observer);
		if (this.stack[0]) {
			observer(this.stack[0]);
		}
		return {
			unsubscribe: once(() => this.observers.splice(index, 1))
		}
	}

	unsubscribe(observer) {
		const index = this.observers.findIndex(whereEqual(observer));
		if (index < 0) return false;
		return Boolean(this.observers.splice(index, 1));
	}

	publish(message) {
		this.stack = [message];
		this.observers.forEach(observer => observer(message));
	}

	reset() {
		this.observers = [];
		this.stack = [];
	}
}
