import Builder from "./Builder";

/**
 * Builder class for constructing shape of the application
 * message with necessary information defined.
 * @property {string} title
 * @property {string} subtitle
 * @property {string} message
 * @property {string} caption
 * @property {string} details
 * @property {(() => void)|null} onSubmit
 * @property {(() => void)|null} onClose
 * @property {number} timeout
 * @property {typeof MESSAGE_STATUS} status
 * @property {typeof MESSAGE_TYPE} type
 */
class MessageBuilder extends Builder {
	static builder() {
		return (new MessageBuilder());
	}

	constructor() {
		super();
		this.setTitle = this.setTitle.bind(this);
		this.setSubtitle = this.setSubtitle.bind(this);
		this.setMessage = this.setMessage.bind(this);
		this.setCaption = this.setCaption.bind(this);
		this.setDetails = this.setDetails.bind(this);
		this.setOnSubmit = this.setOnSubmit.bind(this);
		this.setOnClose = this.setOnClose.bind(this);
		this.setResetButtonContent = this.setResetButtonContent.bind(this);
		this.setTimeout = this.setTimeout.bind(this);
		this.setStatus = this.setStatus.bind(this);
		this.setType = this.setType.bind(this);
	}

	reset() {
		this.setTitle();
		this.setSubtitle();
		this.setMessage();
		this.setCaption();
		this.setDetails();
		this.setOnSubmit();
		this.setOnClose();
		this.setResetButtonContent();
		this.setTimeout();
		this.setStatus();
		this.setType();
	}

	build() {
		return {
			title: this.title,
			subtitle: this.subtitle,
			message: this.message,
			caption: this.caption,
			details: this.details,
			onSubmit: this.onSubmit,
			onClose: this.onClose,
			resetButtonContent: this.resetButtonContent,
			timeout: this.timeout,
			status: this.status,
			type: this.type,
		}
	}

	/**
	 * Configure message title.
	 * @param {string} nextTitle
	 * @returns
	 */
	setTitle(nextTitle = "") {
		this.title = nextTitle;
		return this;
	}
	/**
	 * Configure message subtitle.
	 * @param {string} nextSubtitle
	 */
	setSubtitle(nextSubtitle = "") {
		this.subtitle = nextSubtitle;
		return this;
	}
	/**
	 * Configure message content.
	 * @param {string} nextMessage
	 */
	setMessage(nextMessage = "") {
		this.message = nextMessage;
		return this;
	}
	/**
	 * Configure message caption.
	 * @param {string} nextCaption
	 */
	setCaption(nextCaption = "") {
		this.caption = nextCaption;
		return this;
	}
	/**
	 * Configure message details.
	 * @param {string} nextDetails
	 */
	setDetails(nextDetails = "") {
		this.details = nextDetails;
		return this;
	}
	/**
	 * Configure message on submit callback.
	 * @param {(() => void)|null} nextOnSubmit
	 */
	setOnSubmit(nextOnSubmit = null) {
		this.onSubmit = nextOnSubmit;
		return this;
	}
	/**
	 * Configure message on close callback.
	 * @param {(() => void)|null} nextOnClose
	 * @returns
	 */
	setOnClose(nextOnClose = null) {
		this.onClose = nextOnClose;
		return this;
	}
	/**
	 * Set component or text for the message reset trigger.
	 * @param {*} content
	 * @returns
	 */
	setResetButtonContent(content) {
		this.resetButtonContent = content;
		return this;
	}
	/**
	 * Configure message timeout.
	 * @param {number} nextTimeout
	 */
	setTimeout(nextTimeout = null) {
		this.timeout = nextTimeout;
		return this;
	}
	/**
	 * Configure message status.
	 * @param {string} nextStatus
	 */
	setStatus(nextStatus = null) {
		this.status = nextStatus;
		return this;
	}
	/**
	 *
	 * @param {string} nextType
	 * @returns
	 */
	setType(nextType = null) {
		this.type = nextType;
		return this;
	}
}

export const TransactionBuilder = MessageBuilder;
/**
 * @deprecated Use TransactionBuilder
 */
export default MessageBuilder;
