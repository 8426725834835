import React, { useMemo } from "react";
import { Link } from "react-router-dom";
// Material.UI
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
// Models, libraries, hooks...
import { useStyles } from "./SidebarAvatar.styles";
// DUMMY DATA
import AppRoute from "../../../constants/AppRoute";
// Components
import { Avatar } from "../../atom/Avatar";
import { Typography } from "../../atom/Typography";
import { useSession } from "../../../hooks/useSession";
import withProfilePicture from "../../hoc/withProfilePicture";
import UserOnlineStatus from "../../../constants/UserOnlineStatus";

function SidebarAvatar(props) {
	const classes = useStyles();
	const session = useSession();

	const ProfilePicture = useMemo(() => {
		return withProfilePicture(Avatar);
	}, []);

	return (
		<Box className={classes.sidebarAvatar} marginTop={1}>
			<Grid container spacing={2}>
				<Grid item className={classes.sidebarAvatarItem} xs={12}>
					<Box className={classes.profilePictureContainer}>
						<Link to={AppRoute.PROFILE + "/" + session.getUserId()}>
							<ProfilePicture
								alt={session.getName()}
								userId={session.getUserId()}
								profilePictureAwsKey={session.user.profilePictureAwsKey}
								className={classes.sidebarAvatarImage}
							/>
						</Link>
						{(session.user.isOnline === UserOnlineStatus.ONLINE) && <span className={classes.status}/>}
					</Box>
				</Grid>
				<Hidden mdDown>
					<Grid item className={classes.sidebarAvatarItem} xs={12}>
						<Typography variant='h6' className={classes.sidebarAvatarName}>
							{session.getName()}
						</Typography>
					</Grid>
				</Hidden>
			</Grid>
		</Box>
	);
};

SidebarAvatar.displayName = "SidebarAvatar";
export default SidebarAvatar;
