import React, { useCallback, useMemo, useState } from "react";
import { TextField } from "@material-ui/core";
import { VisibilityIcon, VisibilityOffIcon } from "../Icon";
import { useStyles } from "./PasswordField.styles";
import { classNames } from "../../../util/strings";

function PasswordField(props) {
	const inputProps = useMemo(() => ({
		readOnly: props.readOnly
	}), [props.readOnly]);

	const styles = useStyles();

	const [type, setType] = useState(props.type);

	const onClick = useCallback(() => {
		setType(type === "password" ? "text" : "password");
	}, [type]);

	return (
		<div data-testid={props.testId} className={styles.container}>
			<TextField
				type={type}
				id={props.id}
				className={classNames(
					props.className,
					styles.passwordField
				)}
				variant={props.variant}
				name={props.name}
				label={props.label}
				value={props.value}
				placeholder={props.placeholder}
				error={props.error}
				helperText={props.helperText}
				onChange={props.onChange}
				inputProps={inputProps}
				fullWidth={props.fullWidth}
				autoComplete={props.autoComplete}
			/>
			<button
				title={`${type === "password" ? "prikaži" : "sakrij"} lozinku`}
				onClick={onClick}
				className={styles.showButton}
				type="button"
			>
				{type === "password" ? <VisibilityIcon /> : <VisibilityOffIcon />}
			</button>
		</div>
	)
}

PasswordField.defaultProps = {
	variant: "outlined",
	type: "password"
};
PasswordField.displayName = "PasswordField";
export default PasswordField;
