import { useCallback } from "react";
import { Redirect, Route } from "react-router";
import { useSession } from "../../../hooks/useSession";
import AppRoute from "../../../constants/AppRoute";
import EmployeeStatusContainer from "containers/EmployeeStatusContainer";

function AuthenticatedRoute({component: Component, ...rest}) {
	const session = useSession();

	/* eslint-disable */
	const render = useCallback(function renderRouteComponent(props) {
		if (!session || !session.isAuthenticated()) {
			return <Redirect to={{
				pathname: AppRoute.LOGIN,
				state: {
					referrer: props.location
				}
			}} />;
		}

		return (
			<EmployeeStatusContainer>
				<Component {...props} />
			</EmployeeStatusContainer>
		)
	}, [rest, session]);

	return <Route {...rest} render={render} />
}

AuthenticatedRoute.displayName = "AuthenticatedRoute";
export default AuthenticatedRoute;
