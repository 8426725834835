import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
	navMenu: {
		display: "flex",
		position: "relative"
	},
	navMenuRow: {
		justifyContent: "flex-end",
		alignItems: "center"
	},
	navMenuItem: {
		display: "flex",
		cursor: "pointer",
		padding: theme.spacing(1)
	},
	navMenuIcon: {
		display: "flex",
		marginRight: theme.spacing(0.5)
	},
	navMenuText: {
		textDecoration: "none"
	},
	navMenuMapsOnlineUsers: {
		display: "flex",
		position: "absolute",
		top: "50%",
		transform: "translateY(-50%)"
	},
	navMenuMapsOnlineIcon: {
		color: green[400],
		marginRight: theme.spacing(1),
	},
	linkSupport: {
		margin: "0 10px",
		textTransform: "uppercase",
		textDecoration: "underline",
		fontWeight: "bold"
	}
}));
