import { isString } from "./assertions";

export function ensureTrailingSlash(input) {
  return input.replace(/\/?$/, "/");
}
export function ensureTrailingQuestionMark(input) {
  return input.replace(/\??$/, "?");
}
export function createRandomString(length = 8) {
	const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	let output = "";
	for (let index = 0; index < length; index++) {
		output += chars.charAt(Math.random() * chars.length >> 0);
	}
	return output;
}
/**
 * Naive utility implementation to interpolate string pattern and fill in with data.
 * @param {*} input
 * @param {*} params
 * @returns
 * @example
 */
export function interpolate(input, params = {}) {
	if (!isString(input)) {
		throw new Error("Cannot interpolate non string input.");
	}
	let formattedString = input;
	for (const [key, value] of Object.entries(params)) {
		// TODO: XSS escape value.
		const val = value || '';
		formattedString = formattedString.replace(
			new RegExp('{{(' + key + ')}}', 'gi'),
			val.toString()
		);
	}
	return formattedString;
}
/**
 * Extract content from the JTW.
 * @param {string} token
 * @returns
 */
export function parseJwt(token) {
	var base64Url = token.split(".")[1];
	var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
	var jsonPayload = decodeURIComponent(
		atob(base64)
			.split("")
			.map(function (c) {
				return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
			})
			.join("")
	);
	return JSON.parse(jsonPayload);
}

export function classNames() {
	const output = [];
	const args = Array.from(arguments);
	for (let index = 0; index < args.length; index++) {
		if (typeof args[index] === "string" && args[index].trim() !== "") {
			output.push(args[index].trim());
		}
	}
	return output.join(" ");
}
