import React, { useCallback } from "react";
import { useForm } from "react-hook-form";
import { FormRow } from "../../molecule/FormRow";
import { EmailField } from "../../molecule/EmailField";
import { Button } from "../../atom/Button";
import { getValidResponseBody } from "../../../util/api";
import { isFunction, isResponse } from "../../../util/assertions";
import UserDAO from "../../../api/dao/UserDAO";

function ForgotPasswordForm(props) {
	const {
		testId,
		className,
		onSubmit,
		onError,
		email
	} = props;
	const { control, handleSubmit, formState: { isDirty, isValid }, setError } = useForm({
		defaultValues: {
			email: email || ""
		},
		mode: "onChange"
	});

	const processValidFormData = useCallback(async function processValidFormData({ email }) {
		await UserDAO.query()
			.forgotPassword({ email })
			.then(getValidResponseBody)
			.then(onSubmit)
			.catch(error => {
				console.debug(error);
				const { response } = error;
				if (isResponse(response)) {
					if (response.status === 404) {
						setError("email", { message: "Sistem nije pronašao korisnika sa unesenom email adresom." });
						return;
					}
				}
				if (isFunction(onError)) {
					onError(error);
				}
			});
	}, [setError, onSubmit, onError]);

	return (
		<form onSubmit={handleSubmit(processValidFormData)} className={className} data-testid={testId}>
			<FormRow>
				<EmailField control={control} />
			</FormRow>
			<FormRow>
				{isFunction(onSubmit) && (
					<Button type="submit" fullWidth={true} disabled={!isDirty || !isValid} size="large">
						Pošalji zahtev
					</Button>
				)}
			</FormRow>
		</form>
	);
}

ForgotPasswordForm.displayName = "ForgotPasswordForm";
export default ForgotPasswordForm;
