import { makeStyles } from "@material-ui/core/styles";
import BackgroundImage from "../images/background-main.jpg";

export const useStyles = makeStyles((theme) => ({
  loginContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundImage: `url(${BackgroundImage})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
		minWidth: "100%",
    minHeight: "100vh",
    margin: "0",
    padding: "0",
    [theme.breakpoints.up("md")]: {
      alignItems: "flex-start",
    },
  },
  loginCard: {
		width: "100%",
		maxWidth: 300,
    marginLeft: "0",
    [theme.breakpoints.up("md")]: {
      marginLeft: "15%",
    },
  },
  loginButton: {
    marginTop: theme.spacing(2),
  },
  loginLogo: {
		boxSizing: "border-box",
    marginBottom: theme.spacing(1),
		padding: `0 ${theme.spacing(2)}px`,
		"& > img": {
			width: "100%"
		}
  },
	centeredLink: {
		textAlign: "center"
	}
}));
