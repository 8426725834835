import { sendRequest } from "../api/network/sendRequest";
import { getResponseBody, validateResponse } from "../util/api";
import ApiEndpoint from "../constants/ApiEndpoint";
import { AUTH_STORAGE_KEY } from "../constants/backend";
import HTTP from "../constants/HTTP";
import UserRole from "../constants/UserRole";
import RequestBuilder from "../package/RequestBuilder";
import Subject from "../package/Subject";
import { pipe } from "../util/functions";
import { parseJwt } from "../util/strings";
import UserOnlineStatus from "../constants/UserOnlineStatus";

export default class SessionService {
	/**
	 *
	 * @returns {SessionService}
	 */
	static getInstance() {
		return SessionService.instance;
	}

	static boot() {
		return new Promise(resolve => {
			const item = localStorage.getItem(AUTH_STORAGE_KEY);
			const session = new SessionService();

			if (item) {
				const credentials = JSON.parse(item);
				if (credentials.accessToken) {
					const jwtContent = parseJwt(credentials.accessToken);
					session.accessToken = credentials.accessToken;
					session.user = new Proxy({ id: jwtContent.id, isSuperAdmin: jwtContent.is_super_admin }, {
						set: (target, property, value) => {
							target[property] = value;
							this.publish(target);
							return target;
						}
					});
				}
				if (credentials.refreshToken) {
					session.refreshToken = credentials.refreshToken;
				}
				if (credentials.expiresIn) {
					session.expiresIn = new Date(credentials.expiresIn);
				}
			}

			resolve(session);
		});
	}

	constructor() {
		if (SessionService.instance instanceof SessionService) {
			return SessionService.instance;
		}
		this.authenticate = this.authenticate.bind(this);
		this.refresh = this.refresh.bind(this);
		this.terminate = this.terminate.bind(this);
		this.reset = this.reset.bind(this);
		this.events = new Subject();
		this.reset();
		SessionService.instance = this;
	}

	async authenticate(username, password) {
		try {
			const response = await sendRequest(
				RequestBuilder.builder()
					.setURL(ApiEndpoint.TOKEN)
					.setMethod(HTTP.METHODS.POST)
					.setBody(new URLSearchParams([
						["grant_type", "password"],
						["username", username],
						["password", password],
						["scope", "read"]
					])).setHeaders({
						"Content-Type": "application/x-www-form-urlencoded",
						Authorization: "Basic cGxhbm5lcjpzZWNyZXQ=",
						Accept: "*/*",
					}).build()
			);
			if (!response.ok) {
				return {
					error: "Nije pronađen korisnik sa unesenim korisničkim imenom i lozinkom.",
				};
			}
			const payload = await response.json();
			const jwtContent = parseJwt(payload.access_token);
			this.accessToken = payload.access_token;
			this.refreshToken = payload.refresh_token;
			this.expiresIn = new Date(Date.now() + payload.expires_in * 1000);
			this.user = new Proxy({ id: jwtContent.id, isSuperAdmin: jwtContent.is_super_admin }, {
				set: (target, property, value) => {
					target[property] = value;
					this.publish(target);
					return target;
				}
			});
			localStorage.setItem(AUTH_STORAGE_KEY, JSON.stringify(this));
			return { error: "", session: this };
		} catch (error) {
			console.error(error);
			return {
				error: error.message,
			};
		}
	}

	async refresh() {
		try {
			console.debug("Refreshing Session token...");
			const request = RequestBuilder.builder()
				.setURL(ApiEndpoint.TOKEN)
				.setMethod(HTTP.METHODS.POST)
				.setHeaders({
					"Content-Type": "application/x-www-form-urlencoded",
					Authorization: "Basic cGxhbm5lcjpzZWNyZXQ=",
					Accept: "*/*",
				}).setBody(new URLSearchParams([
					["grant_type", "refresh_token"],
					["scope", "read"],
					["refresh_token", this.refreshToken],
					["valid_for", 43199],
				])).build();
			const response = await sendRequest(request);
			if (!response.ok) {
				return {
					error: (await response.json()).message
				};
			}
			const payload = await response.json();
			this.accessToken = payload.access_token;
			this.refreshToken = payload.refresh_token;
			this.expiresIn = new Date(Date.now() + payload.expires_in * 1000);
			localStorage.setItem(AUTH_STORAGE_KEY, JSON.stringify(this));

			await this.loadSessionUser();

			return {
				error: ""
			};
		} catch (error) {
			console.error(error);
			return {
				error: error.message
			};
		}
	}

	async loadSessionUser() {
		const response = await sendRequest(
			RequestBuilder.builder()
				.setURL(`${ApiEndpoint.USERS}/${this.user.id}`)
				.setHeader("Authorization", `Bearer ${this.accessToken}`)
				.build()
		)

		try {
			const user = await pipe(validateResponse, getResponseBody)(response);
			this.replaceUser(user);
			localStorage.setItem(AUTH_STORAGE_KEY, JSON.stringify(this));
			return user;
		} catch (error) {
			if (error.response) {
				const payload = await error.response.json();
				return {
					error: payload.message
				}
			} else {
				return {
					error: "Neuspelo dobavljanje informacija o korisniku."
				}
			}
		}
	}

	reset() {
		this.events.reset();
		this.accessToken = null;
		this.refreshToken = null;
		this.expiresIn = null;
		this.user = new Proxy({ id: null, isSuperAdmin: false }, {
			set: (target, property, value) => {
				target[property] = value;
				this.publish(target);
				return target;
			}
		});
	}

	terminate() {
		this.reset();
		localStorage.removeItem(AUTH_STORAGE_KEY);
	}

	subscribe(callback) {
		return this.events.subscribe(callback);
	}

	unsubscribe(callback) {
		return this.events.unsubscribe(callback);
	}

	publish(data) {
		this.events.publish(data);
		return this;
	}

	// Helpers
	isAdmin() {
		return this.user.role === UserRole.ADMIN;
	}

	isSuperAdmin() {
		return this.user.isSuperAdmin;
	}

	isOnline() {
		return this.user.isOnline === UserOnlineStatus.ONLINE;
	}

	getUserId() {
		return this.user.id;
	}

	getName() {
		return this.user.firstName + " " + this.user.lastName;
	}

	isAuthenticated() {
		return Boolean(this.user && this.user.id);
	}

	isExpired() {
		return Boolean(!this.expiresIn || Date.now() >= this.expiresIn.getTime());
	}

	replaceUser(nextUser) {
		this.user = new Proxy({ ...this.user, ...nextUser }, {
			set: (target, property, value) => {
				target[property] = value;
				this.publish(target);
				return target;
			}
		});
		this.publish(this.user);
	}

	toJSON() {
		return {
			accessToken: this.accessToken,
			refreshToken: this.refreshToken,
			expiresIn: this.expiresIn
		}
	}
}
