import React from "react";
import { Box } from "@material-ui/core";
import { Typography } from "../atom/Typography";

function PrivacyPolicyPage(props) {
	return (
		<Box>
			<Typography>Privacy Policy</Typography>
		</Box>
	)
}

PrivacyPolicyPage.displayName = "PrivacyPolicyPage";
export default PrivacyPolicyPage;
