import NetworkErrorFactory from "../api/package/NetworkErrorFactory";
import { ToastType } from "../constants/ToastType";
import { TransactionType } from "../constants/Transaction";
import { DialogBuilder } from "../package/DialogBuilder";
import { isBlockingStatusCode } from "../util/assertions";
import EventService from "./EventService";

export default class ApplicationService {
	/**
	 *
	 * @returns {ApplicationService}
	 */
	static getInstance() {
		return ApplicationService.instance;
	}

	static async boot() {
		const applicationService = new ApplicationService();
		applicationService.event = EventService.getInstance();
		return applicationService;
	}

	constructor() {
		if (ApplicationService.instance instanceof ApplicationService) {
			return ApplicationService.instance;
		}

		// Shorthands
		this.showNotFoundScreen = this.showNetworkErrorScreen.bind(this, 404);
		this.showUnauthorizedScreen = this.showNetworkErrorScreen.bind(this, 403);
		this.confirmDeletion = this.confirmDeletion.bind(this);
		this.showToast = this.showToast.bind(this);
		this.showErrorToast = this.showToast.bind(this, ToastType.ERROR);
		this.showWarningToast = this.showToast.bind(this, ToastType.WARNING);
		this.showInfoToast = this.showToast.bind(this, ToastType.INFO);

		ApplicationService.instance = this;
	}

	confirmDeletion(options = {}) {
		const DEFAULT_MSG = "Da li zaista želite da obrišete ovaj zapis?";
		return new Promise((resolve, reject) => {
			this.event.publish(
				DialogBuilder.builder()
					.setType(DialogBuilder.Type.CONFIRMATION)
					.setContent(options.message || DEFAULT_MSG)
					.setCloseButtonText(options.closeButtonText || "Otkaži")
					.setSubmitButtonText(options.submitButtonText || "Obriši")
					.setOnSubmit(resolve)
					.setOnClose(reject)
					.build()
			);
		});
	}

	showNetworkErrorScreen(errorCode) {
		return new Promise((resolve, reject) => {
			const networkError = NetworkErrorFactory.create(errorCode);
			if (isBlockingStatusCode(errorCode)) {
				this.event.publish(networkError);
				return resolve();
			};
			reject();
		});
	}

	showToast(toastType, message = "") {
		this.event.publish({
			type: TransactionType.TOAST,
			payload: {
				message: message,
				toastType
			}
		});
	}

	showSuccessToast(message) {
		this.showToast.call(this, ToastType.SUCCESS, message);
	}
}
