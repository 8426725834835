import React from "react";
import { DialogContent as DialogContentCore } from "@material-ui/core";

/**
 * Facade component abstracting 3rd party DialogContent component
 * exposing only used properties to the application developer.
 * @param {{
 *  className?: string;
 *  children?: React.ReactNode;
 *  dividers?: boolean;
 * }} props 
 * @returns 
 */
function DialogContent(props) {
  return (
    <DialogContentCore
      className={props.className}
      dividers={props.dividers}
    >
      {props.children}
    </DialogContentCore>
  )
}

DialogContent.defaultProps = {};
DialogContent.displayName = "DialogContent";
export default DialogContent;