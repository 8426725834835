import { makeStyles } from "@material-ui/core/styles";

export const useClassNames = makeStyles((theme) => ({
	sidebar: {
		width: "300px",
		flexShrink: 0,
		whiteSpace: "nowrap",
		backgroundColor: theme.palette.primary.main,
		color: "white",
		padding: theme.spacing(5),
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		[theme.breakpoints.down("md")]: {
			width: "120px",
			padding: theme.spacing(2),
		},
	},
}));
