import { makeStyles } from "@material-ui/core/styles";

export const useClassNames = makeStyles((theme) => ({
  root: {
    display: "flex",
    minHeight: "100vh",
    background: "white",
  },
  main: {
		position: "relative",
    flexGrow: 1,
		display: "flex",
		flexDirection: "column",
    minWidth: "700px",
    [theme.breakpoints.down("md")]: {
      minWidth: "900px",
    },
  },
	navMenuContainer: {
		position: "absolute",
		top: 0,
		left: 0,
		width: "100%",
		padding: theme.spacing(2.5)
	},
	footer: {
		flex: 1,
		display: "flex",
		justifyItems: "center",
		alignItems: "flex-end",
		padding: "0 30px 5px",
		fontSize: "1.2rem"
	}
}));
